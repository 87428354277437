import Informations from '@amo/core/assets/images/icons/informations.png';
import Inclus from '@amo/core/assets/images/icons/valide.png';
import { showModal } from '@amo/core/utils/functions';
import { normalizeEuro } from '@amo/core/utils/normalize';
import CodePromoContainer from '../CodePromo/CodePromoContainer';
import Options from '../Options/Options';
import SouscrireContainer from '../Souscrire/SouscrireContainer';
import velo from "@amo/core/assets/images/produits/velo.svg";
import _ from "lodash";
import {Field} from "redux-form";
import {required} from '@amo/core/utils/validateField';
import {QuestionLabel} from "@amo/core/components/containers";
import SlideContainer from "../../../Slide/SlideContainer.js";

const TableauGarantiesMobile = (props) => {
    const { vehicule, formuleSelectionnee, setStep, listeFormules, isDirect, isAvenant, honorairesMax, apporteur } = props;
    const formuleChoisie = _.find(listeFormules, {'CodeFormule': formuleSelectionnee})

    return (
        <div className={'d-block d-lg-none mt-2 bg-grey'}>
            <div className={'row bg-grey'}>
                <div className={'bg-blue rounded-bottom-medium p-2 w-100'}>
                    <p className={'text-white f-17 fw-bold mx-5 mt-3 mb-3'}>
                        Voici nos recommandations personnalisées pour assurer votre vélo {vehicule.Designation}
                    </p>

                    <div className={'py-2 px-3 bg-white mt-3 rounded'}>
                        <p className={'f-14 text-dark text-start fw-bold'}>
                            Votre deux-roues{' '}
                            <span
                                className={'text-secondary f-12 float-end underline-link cursor-pointer'}
                                onClick={() => setStep(0)}
                            >
                                modifier
                            </span>
                        </p>
                        <div className={'row mt-2'}>
                            <div className="col-3">
                                <img src={velo} alt="Véhicule" className={'pt-2'} />
                            </div>

                            <div className="col-7 text-start">
                                <p className={'m-0 fw-bold'}>
                                    {vehicule.Designation}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {formuleChoisie && (
                <>
                    <div className="row bg-grey p-3">
                        <div id={'detail-formule'} className="col-12 shadow border rounded mt-4 border-disabled">
                            <div className={'row py-4 px-2 bg-lighter rounded-top'}>
                                <div className="col-6 text-start">
                                    <p className={'fw-bold f-14 text-dark'}>Votre formule</p>
                                    <p className={'fw-bold f-20 text-dark'}>
                                        {formuleChoisie.LibelleFormule.toUpperCase()}
                                    </p>
                                </div>
                                <div className="col-6 text-end">
                                    <p className={'f-12'}>
                                            <span className={'f-20 fw-bold text-secondary'}>
                                                {normalizeEuro(formuleChoisie.PrimeMensuelleBase)}
                                            </span>{' '}
                                        /mois<sup>*</sup>
                                    </p>
                                    <small className={'f-12'}>
                                        soit{' '}
                                        <span className={'f-14 fw-bold'}>
                                                {normalizeEuro(formuleChoisie.PrimeMensuelleBase * 12)}
                                            </span>{' '}
                                        /an<sup>*</sup>
                                    </small>
                                    <p className={'f-12'}>
                                        <sup>*</sup>Prix TTC
                                    </p>
                                </div>
                            </div>

                            <div className="row bg-white px-2">
                                <div className="col-12 text-start mt-4">
                                    <p className={'f-16 fw-bold text-dark'}>Vos garanties</p>
                                </div>
                                <div className="col-12 px-4">
                                    {formuleChoisie?.ListeGaranties?.map((garantie, index) => {
                                        return (
                                            garantie.Disponible === 'Inclusion' && (
                                                <div
                                                    className={`row ${index > 0 && 'border-top border-2'} py-4`}
                                                    key={index}
                                                >
                                                    <div className="col-1 text-start ps-0">
                                                        <img src={Inclus} alt="Inclus" className={'align-middle'} />
                                                    </div>
                                                    <div className="col text-start">
                                                        <p className={'f-14 text-dark fw-bold'}>
                                                            {garantie.LibelleGarantie}
                                                        </p>
                                                    </div>
                                                    <div
                                                        className="col-2 pe-0 text-end"
                                                        onClick={() =>
                                                            showModal({
                                                                title: garantie.LibelleGarantie,
                                                                icon: 'info',
                                                                html: garantie.ConditionsIndemnisation,
                                                            })
                                                        }
                                                    >
                                                        <img src={Informations} alt="?" className={'align-middle'} />
                                                    </div>
                                                </div>
                                            )
                                        );
                                    })}

                                    <Options {...props} />

                                    <CodePromoContainer {...props} />
                                </div>
                            </div>
                        </div>

                        {
                            !isDirect && !isAvenant && apporteur !== 43397 && <>
                                <QuestionLabel className={'f-16 mt-4 text-center col-12'}>Votre taux de commission<sup className={'text-danger'}>*</sup></QuestionLabel>
                                    <div className={'col-12 col-md-8 offset-md-2'}>
                                        <Field
                                            component={SlideContainer}
                                            name={'DemandeTarif[Police][TauxApporteur1]'}
                                            step={1}
                                            min={0}
                                            max={honorairesMax}
                                            validate={required}
                                            maxBackground={'bg-success'}
                                            minBackground={'bg-success'}
                                            minColor={'text-white'}
                                            maxColor={'text-white'}
                                            symbole={'€'}
                                        />
                                    </div>

                                <QuestionLabel className={'f-16 mt-4 text-center col-12'}>Vos frais de dossier<sup className={'text-danger'}>*</sup></QuestionLabel>
                                    <div className={'col-12'}>
                                        <Field
                                            component={SlideContainer}
                                            name={'DemandeTarif[Police][Honoraires]'}
                                            step={1}
                                            min={0}
                                            max={honorairesMax}
                                            validate={required}
                                            maxBackground={'bg-success'}
                                            minBackground={'bg-success'}
                                            minColor={'text-white'}
                                            maxColor={'text-white'}
                                            symbole={'€'}
                                        />
                                    </div>
                            </>
                        }

                        <SouscrireContainer formuleChoisie={formuleChoisie} {...props} />
                    </div>
                </>
            )}
        </div>
    );
};

export default TableauGarantiesMobile;
