import { normalizeEuro } from '@amo/core/utils/normalize';

const getPriceByFractionnementOrCodePromo = (formule, fractionnement) => {
    let obj = {};
    if (fractionnement === 'M') {
        obj.prix = normalizeEuro(formule?.PrimeMensuelleBase);
        obj.prixPromo = normalizeEuro(formule?.PrimeMensuelleBasePromo);
    } else {
        obj.prix = normalizeEuro(formule?.PrimeAnnuelleFracAnnuelBase);
        obj.prixPromo = normalizeEuro(formule?.PrimeAnnuelleFracAnnuelBasePromo);
    }

    return obj;
};

export default getPriceByFractionnementOrCodePromo;
