import {useState} from 'react';
import PropTypes from 'prop-types'
import {Modal} from "@amo/core/components/containers";

const PopupDevis = (props) => {
    const { isDirect, email, vehicule, handleSubmit, nextStep, pdfDevis, conventionAssistance, dispositionGenerale, documentInformation, ficheConseil, gpcSouscription } = props
    const [open, setOpen] = useState(true)

    return (
        <>
            {
                open &&
                <Modal onClick={() => {setOpen(!open); document.body.classList.remove('modal-open');}}>
                    <div className={'text-center'}>
                        <h3 className={'card-title'}>Votre proposition d'assurance</h3>
                        {
                            gpcSouscription && <p className={'card-text'}>Votre proposition vous a été envoyée à :<br/><b
                                className={'text-primary-dark'}>{email}</b>.</p>
                        }

                        <div className={'mt-3'}>
                            <h5>{isDirect ? `N'attendez plus et assurez votre ${vehicule} immédiatement` : "N'attendez plus et assurez le vélo de votre client immédiatement"}&nbsp;!</h5>
                            <button className={`btn btn-primary medium-rounded my-3`} onClick={() => {setOpen(!open);document.body.classList.remove('modal-open');handleSubmit(nextStep)()} }
                                    data-id="btn-finalisation">JE FINALISE LA SOUSCRIPTION
                            </button>
                            <a href={pdfDevis} className="card-link bold d-block" target={'_blank'} rel="noreferrer">Télécharger la
                                proposition d'assurance</a>
                        </div>

                        <div className={'mt-5'}>
                            <p className={'card-text'}>Retrouvez aussi en téléchargement :</p>
                            <ul className={'list-unstyled'}>
                                {ficheConseil && <li><a href={ficheConseil} target={'_blank'} rel="noreferrer">Fiche conseil</a></li>}
                                {documentInformation &&
                                <li><a href={documentInformation} target={'_blank'} rel="noreferrer">Document d'information</a></li>}
                                {dispositionGenerale &&
                                <li><a href={dispositionGenerale} target={'_blank'} rel="noreferrer">Dispositions Générales</a></li>}
                                {conventionAssistance &&
                                <li><a href={conventionAssistance} target={'_blank'} rel="noreferrer">Annexe Assistance</a></li>}
                            </ul>
                        </div>
                    </div>
                </Modal>
            }
        </>
    );
};

PopupDevis.defaultProps = {
    email: 'votre adresse mail',
    vehicule: 'véhicule'
};

PopupDevis.propTypes = {
    email: PropTypes.string,
    vehicule: PropTypes.string
};

export default PopupDevis;
