import {connect} from 'react-redux'
import {getGestionnaireInit} from "../../../../../redux/selectors/initSession/initSession.selectors";
import FooterLight from "@amo/core/components/containers/Footer/FooterLight.jsx";

const mapStateToProps = state => {
    return {
        gestionnaireIsAMO: getGestionnaireInit(state).codeBelair === 43397
    }
}

const FooterContainer = connect(mapStateToProps)(FooterLight)

export default FooterContainer
