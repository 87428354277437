import _ from 'lodash';
import moment from "moment";
import React from "react";

/**
 * Formatte les données pour les envoyer à l'API en datalayer
 * @param formType
 * @param data
 * @returns {{}}
 */
export const formatDataForAnalytics = (formType, data) => {
    let newDatas = {};

     _.map(findFormField(formType), (newLabel, oldLabel)  => {
         let dataFormValue =_.get(data, oldLabel);

        if(dataFormValue || dataFormValue === false || dataFormValue === '0')  {
            let newValue = '';
            // Dans certains cas la donnée doit être formattée pour être envoyée
            if(formType === 'moto'){
                newValue = formatDataForMoto(oldLabel, newLabel, dataFormValue);
            } else if(formType === 'trottinette'){
                newValue = formatDataForTrottinette(oldLabel, newLabel, dataFormValue);
            } else if(formType === 'velo'){
                newValue = formatDataForVelo(oldLabel, newLabel, dataFormValue);
            } else {
                newValue = dataFormValue;
            }

            //Si on a déjà une entrée dans le tableau pour la même clé on met à jour sinon on crée
            if(newValue !== ''){
                newDatas = {
                    ...newDatas,
                    [newLabel]: `${newDatas[newLabel] ? `${newDatas[newLabel]}, ` : ''}${newValue}`
                };
            }
        }
     });
    return newDatas;
};

const findFormField = (formType) => {
    return FieldsLabel[formType];
}

export function formatDataForMoto(oldLabel, label, value){
    let newdataFormValue = '';

    switch (label) {
        case 'use' :
            return usages[value] ?? value;
        case 'drivingLicence':
            newdataFormValue = _.mapValues(value, function(o) {return o?.TypePermis });
            return _.join(_.reject(_.values(newdataFormValue), function(o) { return !o; }), ', ');
        case 'bonus' :
            return Number(value) <= 1 ? 'Bonus' : 'Malus';
        case 'carteGrise' :
            return titulaireCG[value] ?? value;
        case 'previous' :
            newdataFormValue = [];
            _.forEach({...value}, function(antecedent, type_antecedent) {
                if(antecedent === 1 || antecedent === "1" || antecedent === "true" || antecedent === true){
                    // On redescend uniquement les champs qui ont été cochés
                    newdataFormValue.push(replaceCamelSpaces(type_antecedent));
                } else if(type_antecedent === 'ListeSinistres' && !_.isEmpty(antecedent)){
                    newdataFormValue.push('accident');
                }
            });
            return _.join(newdataFormValue, ', ');
        case 'paymentType' :
            return value === 'A' ? 'annuel' : 'mensuel';
        case 'startDate' :
            if(moment(value, 'DD/MM/YYYY HH:mm', true).isValid()){
                return moment(value, 'DD/MM/YYYY HH:mm', true).format('YYMMDD');
            }
            return '';

        case 'options' :
            if((oldLabel === 'optionsFranchise' || oldLabel === 'optionsListe') && !_.isEmpty(value)){
                let listeoptions = '';

                _.forEach(value, function(option, key) {
                    if(option && option !== "false"){
                        listeoptions = `${listeoptions ? `${listeoptions}, ` : ''}${key}`;
                    }
                });

                return listeoptions;
            } else {
                return _.join(_.values(value), ', ');
            }
        case 'withPromoCode' :
            return value?.Eligible ? 'oui' : 'non';
        case 'link' :
            return RelationsTiersPayeur[value] ?? value;

        case 'concept' :
        default:
            return value;
    }
}

export function formatDataForTrottinette(oldLabel, label, value){
    switch (label) {
        case 'concept' :
            return formulesTrottinette[value] ?? value;
        case 'invalidity' :
            return String(value) === '1' || String(value) === 'true' ? 'oui' : 'non';
        case 'link' :
            return RelationsTiersPayeur[value] ?? value;
        default:
            return value;
    }
}

export function formatDataForVelo(oldLabel, label, value){
    switch (label) {
        case 'vehicleType' :
            return vehiculeTypeVelo[value] ?? value;
        case 'protection' :
            return String(value) === '1' || String(value) === 'true' ? 'oui' : 'non';
        case 'tagPresent' :
            return value ? 'oui' : 'non';
        case 'link' :
            return RelationsTiersPayeur[value] ?? value;
        default:
            return value;
    }
}

export function replaceCamelSpaces(colorName){
    return colorName.replace(/\B([A-Z])\B/g, ' $1');
}

const FieldsLabel = {
    "moto": {
        "typeVehicule": "vehicleType",
        "marqueVehicule": "brand",
        "cylindreeVehicule": "power",
        "DemandeTarif.Vehicule.UsageVehicule": "use",
        "DemandeTarif[ListePersonnes][0][ListePermis]": "drivingLicence",
        "DemandeTarif[ListePersonnes][0][ValeurCrmMoto]": "bonus",
        "titulaireCarteGrise": "carteGrise",
        "DemandeTarif[Antecedents]": "previous",
        "DemandeTarif[Police][FormuleChoisie]": "concept",
        "options": "options",
        "optionsFranchise": "options",
        "optionsListe": "options",
        "DemandeTarif[Police][FractionnementChoisi]": "paymentType",
        "codePromo": "withPromoCode",
        "DemandeContrat[DateHeureEffet]": "startDate",
        "numeroContrat": "contractID",
        "DemandeTarif[ListePersonnes][0][Email]" : "email",
        "DemandeContrat[TiersPayeur][Relationship]" : "link"
    },
    "velo": {
        "DemandeTarif[Vehicule][GenreVehicule]": "vehicleType",
        "DemandeTarif[Vehicule][Designation]": "brand",
        "DemandeTarif[Vehicule][ValeurVehicule]": "vehiclePrice",
        "DemandeTarif[DevoirConseil][DevoirIC]": "protection",
        "yealyQuote": "yearlyQuote",
        "DemandeContrat[VIN]": "tagPresent",
        "DemandeContrat[DateHeureEffet]": "startDate",
        "numeroContrat": "contractID",
        "DemandeContrat[TiersPayeur][Relationship]" : "link",
        "DemandeTarif[ListePersonnes][0][Email]" : "email",
    },
    "trottinette": {
        "DemandeTarif[Police][FormuleChoisie]": "concept",
        "DemandeTarif[ListeOptions][0][Souscrite]": "invalidity",
        "yealyQuote": "yearlyQuote",
        "numeroContrat": "contractID",
        "DemandeContrat[TiersPayeur][Relationship]" : "link",
        "DemandeTarif[ListePersonnes][0][Email]" : "email",
    }
};

const usages = {
    "1": 'Promenade',
    "2": 'Promenade + Trajet travail',
    "3": 'Affaires'
}

const titulaireCG = {
    "1": 'Particulier',
    "3": 'Particulier',
    "2": 'Société',
    "autres": 'Quelqu\'un d\'autre'
}

const formulesTrottinette = {
    "F1": 'Solo',
    "F2": 'Famille'
}

const vehiculeTypeVelo = {
    "VEL": 'Vélo Classique',
    "VAE": 'VAE',
    "VELC": 'Cargo'
}

const RelationsTiersPayeur = {
    "C": 'Conjoint',
    "E": 'Enfant',
    "FS": 'Frère/Sœur',
    "GP": 'Grand-parent',
    "P": 'Parent',
    "A": 'Autres'
}
