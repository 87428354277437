import {Field} from "redux-form";
import {Radio} from "@amo/core/components/forms"
import {ChampsObligatoires, QuestionLabel} from '@amo/core/components/containers'
import {required} from '@amo/core/utils/validateField';
import {SimpleTitle} from "@amo/core/components/containers";

const Besoin = (props) => {
    const {handleSubmit, nextStep, valid, getTexteBySource, unsetValue, isCourtier} = props

    return (
        <div>
            <SimpleTitle icon={'security'}>{getTexteBySource('votre-besoin')}</SimpleTitle>

            {isCourtier && <QuestionLabel className={'f-14 mt-4'}>Posez ces questions à votre client pour mieux comprendre son besoin et ainsi mieux le conseiller.</QuestionLabel> }

            <QuestionLabel className={'f-14 mt-4'}>{getTexteBySource('conseilDommageVelo')}<sup className={'text-danger'}>*</sup></QuestionLabel>
            <div className={'row'}>
                <div className={'col-12 col-md-4 mt-3 mt-md-4'}>
                    <Field
                        component={Radio}
                        type={'radio'}
                        name={'DemandeTarif[DevoirConseil][DevoirIC]'}
                        value={'1'}
                        label={'Oui'}
                        validate={required}
                        className={'text-center'}
                        onChange={() => unsetValue('options')}
                    />
                </div>

                <div className={'col-12 col-md-4 mt-3 mt-md-4'}>
                    <Field
                        component={Radio}
                        type={'radio'}
                        name={'DemandeTarif[DevoirConseil][DevoirIC]'}
                        value={'0'}
                        label={'Non'}
                        validate={required}
                        className={'text-center'}
                        onChange={() => unsetValue('options')}
                    />
                </div>
            </div>

            <div className={'bg-grey p-4 rounded text-left mt-5'}>
                <p dangerouslySetInnerHTML={{__html: getTexteBySource('texteInfoPcc')}} />
            </div>

            <ChampsObligatoires/>

            <div className={'mt-5'}>

                <button
                    className={`${!valid && 'disabled'} btn-arrow btn btn-primary medium-rounded float-right`}
                    onClick={valid ? handleSubmit(nextStep) : null}
                    data-id="btn-next"
                >
                    CONTINUER
                </button>
            </div>

        </div>
    );
};


export default Besoin;
