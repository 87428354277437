import {connect} from 'react-redux';
import NavBar from "@amo/core/components/containers/NavBar/NavBar";
import {getStep} from "../../../../../redux/selectors/ui/ui.selectors";
import {getRoutesByDevice} from "./getRoutesByDevice";
import {getSource} from "../../../../../redux/selectors/initSession/initSession.selectors";
import {formValueSelector} from "redux-form";
import {calculRoutesByRecueilBesoin} from "../../../../../utils/function";

const mapStateToProps = state => {
    const selector = formValueSelector('devisVae')

    return {
        routes: calculRoutesByRecueilBesoin(selector(state, 'DemandeTarif[UseRecueilBesoin]'), getRoutesByDevice(getStep(state))),
        // activeStep: getActiveStepByDevice(getStep(state), getRoutesByDevice(getStep(state))), // ne semble plus fonctionner getstep fonctionne
        activeStep: getStep(state),
        indexQuestion: 0,
        nbQuestions: 0,
        source: getSource(state),
    }
}

const NavBarContainer = connect(mapStateToProps)(NavBar)

export default NavBarContainer
