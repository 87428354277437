import _ from "lodash";
import {SimpleTitle} from "@amo/core/components/containers";


const EnvoiPiecesDirect = (props) => {
    const { documentsUrl, listeDocuments, nameWhitelabel } = props

    return (
        <div>
            <div>
                <div className={'bg-secondary-light p-4 mb-5 text-center rounded'}>
                    <div className={'f-21 text-primary-light mb-3'}>
                        <span className={`icon-triangle d-inline-block position-relative mr-2`} style={{fontSize: 30}} />
                        Il vous reste des documents à nous faire parvenir.
                    </div>
                    <div className={'f-21 mb-3'}>
                        Vous pouvez nous envoyer vos documents via la plateforme d'envoi des documents <br/>{nameWhitelabel}&nbsp;!
                    </div>

                    <div className={'f-18'}>
                        Une plateforme rapide et sécurisée&nbsp;!
                    </div>
                </div>
            </div>

            <div>
                <SimpleTitle icon={'document'}>Vos documents à nous transmettre</SimpleTitle>

                <ul className={'mt-4'}>
                    {
                        _.map(listeDocuments, (d, i) => (
                            <li key={i} className={'p-1'}>{d.Description}</li>
                        ))
                    }
                </ul>
            </div>

            <div className={'row mt-5'}>
                <div className="col text-center">
                    <a href={documentsUrl} className={'btn btn-primary medium-rounded text-uppercase mb-2'}>Envoyer vos documents</a>
                </div>
            </div>
        </div>
    );
};


export default EnvoiPiecesDirect;
