import Nice from '@amo/core/assets/images/AMO_Icons/Nice.svg';
import { Divider } from '@amo/core/components/containers';
import { Radio } from '@amo/core/components/forms';
import { showModal } from '@amo/core/utils/functions';
import _ from 'lodash';
import { useState } from 'react';
import { Field } from 'redux-form';
import { normalizeEuro } from '@amo/core/utils/normalize';
import './bloc-option.scss';

export const BlocOption = (props) => {
    const { icon, title, changeValue, fractionnement, name, data, selected, text, codePromo, goodForMe, from } = props;
    const [tempSelect, setTempSelect] = useState(selected !== undefined ? selected : data[0].value);

    const selectOption = (selected) => {
        if (selected !== undefined) {
            changeValue(name, '');
        } else {
            changeValue(name, tempSelect);
        }
    };

    const buildLabelOption = (o) => {
        if (
            codePromo?.Eligible &&
            (codePromo?.TypePromo === 'ReductionPourcent' || o.dataAffichage.prix > o.dataAffichage.prix_promo)
        ) {
            return `<span>${o.label} pour <b class='text-decoration-line-through text-grey'>${normalizeEuro(
                o.dataAffichage.prix,
            )}</b> <b class='text-secondary'>${normalizeEuro(
                o.dataAffichage.prix_promo,
            )}</b>/${fractionnementChoisi}</span>`;
        } else {
            return `${o.label} pour <b>${normalizeEuro(o.dataAffichage.prix)}</b>/${fractionnementChoisi}`;
        }
    };

    const fractionnementChoisi = fractionnement === 'M' ? 'mois' : 'an';

    return (
        <div className={'col-lg-4 col-sm-6 col-12 mb-4 bloc-option'}>
            <div
                style={{ height: '100%' }}
                className={`position-relative content-bloc-option border rounded mb-3 px-3 pb-3 pt-2 ${
                    selected ? 'border-blue' : 'border-disabled'
                }`}
            >
                <div
                    className={'d-flex flex-column justify-content-between align-items-end'}
                    style={{ height: '100%' }}
                >
                    <div className={'col-12'}>
                        <div className="row align-items-center mb-3">
                            <div className="col">
                                <div
                                    className={
                                        'f-16 text-dark fw-bold text-start my-3 d-flex align-items-center justify-content-start'
                                    }
                                >
                                    <img src={icon} alt={title} width={48} />
                                    <p>
                                        {title}{' '}
                                        {_.find(data, ['value', tempSelect])?.dataAffichage.text && (
                                            <span
                                                onClick={() =>
                                                    showModal({
                                                        title: _.find(data, ['value', tempSelect]).label,
                                                        icon: 'info',
                                                        html: _.find(data, ['value', tempSelect])?.dataAffichage.text,
                                                    })
                                                }
                                                className={'d-inline-block btn-help ms-2'}
                                            >
                                                ?
                                            </span>
                                        )}
                                    </p>
                                </div>
                                <small
                                    className={'float-start mb-3 text-start'}
                                    dangerouslySetInnerHTML={{ __html: text }}
                                />
                                {data.length > 1 ? (
                                    data.map((option, index) => {
                                        return (
                                            <Field
                                                className={`small option`}
                                                key={index}
                                                component={Radio}
                                                type={'radio'}
                                                name={name}
                                                value={option.value}
                                                label={buildLabelOption(option)}
                                                onClick={(e) => setTempSelect(e.target.value)}
                                            />
                                        );
                                    })
                                ) : (
                                    <Field
                                        className={`small option`}
                                        component={Radio}
                                        type={'radio'}
                                        name={name}
                                        value={data[0].value}
                                        label={buildLabelOption(data[0])}
                                        onClick={(e) => setTempSelect(e.target.value)}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    <div className={'col-12'}>
                        {selected ? (
                            <div className={'option-choisi'}>
                                {
                                    goodForMe && <p className={'f-16 fw-bold text-dark text-center'}>
                                        <img src={Nice} alt={'Option Ajoutée'} height={40} />
                                        C'est bon pour moi !
                                    </p>
                                }
                                <Divider />
                                <button
                                    className={'btn btn-outline-primary btn-smaller btn-block f-13'}
                                    onClick={() => selectOption(selected)}
                                >
                                    Je ne souhaite pas souscrire à cette option
                                </button>
                            </div>
                        ) : (
                            <>
                                {
                                   from && <div className={'option-non-choisi bg-white rounded text-start p-3'}>
                                        <p className={'f-15 fw-bolder text-dark'}>
                                            Ajouter cette option
                                            <br />
                                            {codePromo?.Eligible &&
                                            (codePromo?.TypePromo === 'ReductionPourcent' ||
                                                data[0].dataAffichage.prix > data[0].dataAffichage.prix_promo) ? (
                                                <span>
                                            à partir de{' '}
                                                    <b className={'text-grey f-17 text-decoration-line-through'}>
                                                {normalizeEuro(data[0].dataAffichage.prix)}
                                            </b>{' '}
                                                    <b className={'text-secondary f-17'}>
                                                {normalizeEuro(data[0].dataAffichage.prix_promo)}
                                            </b>
                                            /{fractionnementChoisi}
                                        </span>
                                            ) : (
                                                <span>
                                            à partir de{' '}
                                                    <b className={'text-secondary f-17'}>
                                                {normalizeEuro(data[0].dataAffichage.prix_promo)}
                                            </b>
                                            /{fractionnementChoisi}
                                        </span>
                                            )}
                                        </p>
                                    </div>
                                }
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

BlocOption.defaultProps = {
    goodForMe: true,
    from: true
}
