export const FILL = '[Fill]'

export const POST_FILL = `${FILL} Post`

export const postFill = ({step, maxStep, ap1, form, query, url}) => ({
    type: POST_FILL,
    payload: {
        data: query,
        body: form,
        meta:{
            otherData: {
                step,
                maxStep,
                url,
                ap1
            }
        }
    }
})
