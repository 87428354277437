import {connect} from 'react-redux'
import {compose} from 'redux'
import Vehicule from "./Vehicule";
import {reduxForm, formValueSelector, clearFields} from "redux-form";
import {
    getActeGestionDemandeTarifInit,
    getApporteurCanUseRecueilBesoin, getIsComparateurApporteur,
    getSource
} from "../../../../../../redux/selectors/initSession/initSession.selectors";

const mapStateToProps = state => {
    const selector = formValueSelector('devisVae')

    return {
        typeVehicule: selector(state, 'DemandeTarif[Vehicule][GenreVehicule]'),
        isCourtier: getSource(state) === 'courtier',
        isDirect: getSource(state) === 'site',
        isComparateur: getIsComparateurApporteur(state),
        canUseBesoin: getApporteurCanUseRecueilBesoin(state),
        isAvenant: getActeGestionDemandeTarifInit(state) === 'AV'
    }
}

const mapDispatchToProps = {
    unsetValue: (field) => clearFields('devisVae', false, false, field),
}

const VehiculeContainer = compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: 'devisVae',
        destroyOnUnmount: false,
        forceUnregisterOnUnmount: true,
    })
)(Vehicule)

export default VehiculeContainer
