import {useEffect, useState} from 'react';
import {Field} from "redux-form";
import {Text} from "@amo/core/components/forms";
import './signature.scss'
import {normalizeTel} from "@amo/core/utils/normalize.js";
import {QuestionLabel} from "@amo/core/components/containers";


const Signature = (props) => {
    const { url, police, formule, genreVehicule, amount, signaturePhone, signatureEmail, nameWhitelabel } = props
    const [recieptBySms, setRecieptBySms] = useState(true)
    const [phoneSign, setPhoneSign] = useState(signaturePhone)
    const [emailSign, setEmailSign] = useState(signatureEmail)

    // useEffect(() => {
        // /* Matomo */
        // window._paq.push(['addEcommerceItem',
        //     police,
        //     `${genreVehicule} - ${formule.CodeFormule}`
        // ]);
        // window._paq.push(['trackEcommerceOrder',
        //     police,
        //     formule.PrimeAnnuelleFracAnnuel,
        //     amount
        // ]);
    // }, [formule.CodeFormule, formule.PrimeAnnuelleFracAnnuel, amount, genreVehicule, police])
    
    const setSignaturePhone = value => {
        let newValue = value

        if (value.replace(/\s+/g, "").length > 10) {
             newValue = value.substring(0, value.length - 1)
        }

        setPhoneSign(newValue.replace(/\s+/g, ""))
    }

    return (
        <div className={'etapeSignature'}>
            <div className={'bg-secondary-light p-4 mb-5 blocSuccess text-center rounded'}>
                <div className={'f-21 textCheck text-primary-light mb-5'}>
                    <span className={`icon-Shape p-2 border full-rounded border-primary-light d-inline-block position-relative align-middle mr-2`} style={{fontSize: 7}} />
                    Votre paiement a bien été enregistré.
                </div>
                <div className={'f-18 mb-3'}>
                    Un justificatif de paiement vous a été envoyé par email. Merci de le conserver pour toute réclamation.
                </div>

                <div className={'f-21 mb-5'}>
                    Plus rapide et sécurisée, optez pour la signature électronique !
                </div>

                <QuestionLabel className={'f-14 mt-md-4'}>
                    {
                        recieptBySms ?
                            'Merci d\'indiquer votre numéro de téléphone pour recevoir le code de validation'
                            : 'Merci d\'indiquer votre email pour recevoir le code de validation'
                    }
                </QuestionLabel>

                <div className={'row'}>
                    <div className={'col-12 col-md-4 offset-md-4 type_reception'}>
                        {
                            recieptBySms ?
                                <Field
                                    component={Text}
                                    name={'DemandeContrat[SignaturePhone]'}
                                    onChange={(e) => setSignaturePhone(e.target.value)}
                                    normalize={normalizeTel}
                                    icon={'phone'}
                                    iconSize={18}
                                />
                                : <Field
                                    component={Text}
                                    name={'DemandeContrat[SignatureEmail]'}
                                    onChange={(e) => setEmailSign(e.target.value)}
                                    icon={'envelope'}
                                    iconSize={18}
                                />
                        }
                    </div>
                </div>

                <span className={'f-14 cursor-pointer'} onClick={ () => {setRecieptBySms(!recieptBySms)} }>
                    {
                        recieptBySms ?
                            "Je préfère le recevoir par email"
                            : "Je préfère le recevoir par sms"
                    }
                </span>

                <div>
                    <a href={`${url}&method=${recieptBySms ? 'sms' : 'email'}&customer_${recieptBySms ? 'phone' : 'email'}=${recieptBySms ? phoneSign : emailSign}`} className={"btn btn-primary medium-rounded text-uppercase my-4"}>
                        JE SIGNE ET JE TÉLÉCHARGE MES DOCUMENTS AVEC YOUSIGN
                    </a>
                </div>
            </div>

            <div className={'text-center mb-5'}>
                <div className={'row f-14 mb-5 blocIcons'}>
                    <div className={'col-12 col-md-4'}>
                        <span className={`icon-Shape p-2 border full-rounded border-primary-light d-inline-block position-relative align-middle mr-2`} style={{fontSize: 7}} />
                        <div className={'font-weight-bold'}>Je prends connaissance des documents et je les signe</div>
                    </div>
                    <div className={'col-12 col-md-4'}>
                        <span className={`icon-Shape p-2 border full-rounded border-primary-light d-inline-block position-relative align-middle mr-2`} style={{fontSize: 7}} />
                        <div className={'font-weight-bold'}>Je reçois un code par SMS ou par email pour valider la signature</div>
                    </div>
                    <div className={'col-12 col-md-4'}>
                        <span className={`icon-Shape p-2 border full-rounded border-primary-light d-inline-block position-relative align-middle mr-2`} style={{fontSize: 7}} />
                        <div className={'font-weight-bold'}>Je reçois les documents signés par email et j'envoie mes pièces justificatives</div>
                    </div>
                </div>
            </div>

            <div className={'bg-grey p-4 rounded text-left'}>
                <QuestionLabel className={'mt-0 mb-3'}>LA SIGNATURE ÉLECTRONIQUE, C'EST QUOI ?</QuestionLabel>

                <p>La signature électronique est, pour un document numérique, l’équivalent de la signature manuscrite. En vertu du décret n°2001-272 du 30 mars 2001, elle a la même valeur légale qu’une signature manuscrite.</p>
                <p>Le client signe numériquement son contrat de manière totalement sécurisée et il est garanti par un tiers de confiance.</p>
                <p>{nameWhitelabel} a fait confiance à YouSign pour la signature électronique. Ce dernier propose un service certifié conforme aux normes et standards européens, qui permet d'authentifier l’ensemble des auteurs.</p>

                <QuestionLabel className={'f-14 mt-3 mb-2'}>Comment ça marche ? Rien de plus simple :</QuestionLabel>
                <ol>
                    <li>Cliquez sur le bouton “Je signe et je télécharge mes documents”</li>
                    <li>Prenez connaissance de l’ensemble des documents et cliquez sur le bouton “Valider et lancer la signature des fichiers”</li>
                    <li>Saisissez le code reçu par SMS</li>
                    <li>Téléchargez vos documents contractuels signés et conservez-les précieusement</li>
                </ol>
            </div>
        </div>
    );
};


Signature.defaultProps = {};


Signature.propTypes = {};


export default Signature;
