import {connect} from 'react-redux'
import {bindActionCreators, compose} from "redux";
import TiersPayeur from "./TiersPayeur";
import {getListCountries} from "../../../../../../../redux/selectors/ui/ui.selectors";
import {setCountries} from "../../../../../../../redux/actions/app/ui/ui.actions";
import {getActeGestionDemandeTarifInit} from "../../../../../../../redux/selectors/initSession/initSession.selectors";
import {change, formValueSelector} from "redux-form";

const mapStateToProps = state => {
    const selector = formValueSelector('devisVae')
    return {
        tiersPayeurRelationship: selector(state, 'DemandeContrat[TiersPayeur][Relationship]'),
        isAvenant: getActeGestionDemandeTarifInit(state) === 'AV',
        countries: getListCountries(state),
        tiersPayeurType: selector(state, 'DemandeContrat[TiersPayeur][Type]'),
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    setCountries,
    changeValue: (field, value) => change('devisVae', field, value),
}, dispatch)

const TiersPayeurContainer = compose(
    connect(mapStateToProps, mapDispatchToProps)
)(TiersPayeur)

export default TiersPayeurContainer