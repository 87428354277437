import StatusContratContainer from "./StatusContrat/StatusContratContainer";
import CancelPaiement from "./CancelPaiement/CancelPaiement";

const Paiement = (props) => {
    const { isDirect, sendMailPaiement, postPay } = props

    /* Courtier qui a envoyé un mail de paiement */
    if (sendMailPaiement && !isDirect){
        return <StatusContratContainer {...props} />
    } else {
        /* une annulation de à l'étape de la banque */
        return <CancelPaiement {...props} postPay={postPay} />
    }
};

export default Paiement;
