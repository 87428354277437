import {Field} from "redux-form";
import {Modal} from "@amo/core/components/containers";
import {minDateToday, normalizeTel, requiredWithError, requiredWithoutAsap} from "@amo/core/utils/index.js";
import {Checkbox, Select, Text} from "@amo/core/components/forms";
import {SmallSpinner} from "@amo/core/components/loaders";

export const DemandeRappel = (props) => {
    const {formRappelAffiche, setFormRappelAffiche, asap, postDemandeRappel, handleSubmit, postDemandeLoading} = props
    let creneaux = [];
    for (var i = 9; i < 18; i++) {
        creneaux.push(`${i}:00 - ${i}:30`);
        creneaux.push(`${i}:30 - ${i+1}:00`);
    }

    return (
        <form onSubmit={handleSubmit(postDemandeRappel)}>
            {formRappelAffiche && <Modal onClick={ () => { setFormRappelAffiche(!formRappelAffiche); document.body.classList.remove('modal-open'); } } classTitle={'text-primary'} title={'Vous souhaitez être rappelé<br/>pour faire un devis ?'}>

                <p>Notre service commercial est à votre écoute pour toute <strong>demande de devis</strong> du lundi au vendredi de 9h à 19h et le samedi de 10h à 14h</p>
                <p><strong>Si vous êtes déjà client</strong>, merci d'appeler le&nbsp;<strong>02&nbsp;47&nbsp;51&nbsp;07&nbsp;07</strong></p>

                <div className={'row'}>
                    <div className={'col-12 mt-3 mt-md-4'}>
                        <Field
                            component={Text}
                            name={'nom'}
                            validate={requiredWithError}
                            label={'Votre nom'}
                        />
                    </div>
                    <div className={'col-12 mt-3 mt-md-4'}>
                        <Field
                            component={Text}
                            name={'prenom'}
                            validate={requiredWithError}
                            label={'Votre prénom'}
                        />
                    </div>
                    <div className={'col-12 mt-3 mt-md-4'}>
                        <Field
                            component={Text}
                            name={'tel'}
                            icon={'phone'}
                            label={'Numéro de téléphone'}
                            iconSize={18}
                            validate={requiredWithError}
                            normalize={normalizeTel}
                        />
                    </div>
                    <div className={'col-12 mt-3 mt-md-4'}>
                        <Field
                            component={Checkbox}
                            name={'asap'}
                            type={"checkbox"}

                        >Etre rappelé(e) au plus vite</Field>
                    </div>
                    <div className={'col-12 mt-3 mt-md-4'}>
                        <Field
                            component={Text}
                            name={'date'}
                            typeFormat={'date'}
                            label={'Date de rappel souhaitée'}
                            validate={[requiredWithoutAsap, minDateToday]}
                        />
                    </div>
                    <div className={'col-12 mt-3 mt-md-4'}>
                        <Field
                            component={Select}
                            name={'heure'}
                            label={'Heure de rappel souhaitée'}
                            disabled={asap}
                            validate={requiredWithoutAsap}
                        >
                            <option>Sélectionnez...</option>
                            {
                                creneaux.map((creneau, index) => (
                                    <option key={index} value={creneau}>{creneau}</option>
                                ))
                            }
                        </Field>
                    </div>
                    <div className={'col-12 mt-5 mb-4'}>
                        {
                            postDemandeLoading ? <div className={'text-center'}><SmallSpinner className={'btn'} style={{width: 300, marginTop: 0}}/></div>
                                :
                                <button className={`btn btn-primary medium-rounded text-uppercase w-100`} type={'submit'}>Envoyer</button>
                        }
                    </div>
                </div>

            </Modal> }
        </form>
    );
};
