const ErreurTarif = (props) => {
    const { text } = props

    return (
        <div>
            <div className={'bg-secondary-light p-4 mb-5 text-center rounded'}>
                {
                    text !== 'Feu Orange' || text !== 'Feu Rouge' ?
                        <div className={'f-21 text-primary-light mb-3'}>
                            <span className={`icon-triangle d-inline-block position-relative mr-2`} style={{fontSize: 30}} />
                            {text}
                        </div>
                        : <div className={'f-21 text-primary-light mb-3'}>
                            <span className={`icon-triangle d-inline-block position-relative mr-2`} style={{fontSize: 30}} />
                            Vos informations ne nous permettent pas de vous proposer un tarif, n'hésitez pas à nous contacter&nbsp;!
                        </div>
                }
            </div>
        </div>
    );
};

export default ErreurTarif;
