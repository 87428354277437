export const TARIF = '[Tarif]'
export const TARIF_RELOAD = '[TarifReload]'

export const POST_TARIF = `${TARIF} Post`
export const POST_TARIF_RELOAD = `${TARIF_RELOAD} Post`
export const SET_TARIF = `${TARIF} Set`

export const postTarif = ({query, ap1, form, codePromo = false}) => ({
    type: POST_TARIF,
    payload: {
        data: query,
        body: form,
        meta:{
            otherData: {
                ap1,
                codePromo: codePromo
            }
        }
    }
})

export const setTarif = ({data}) => ({
    type: SET_TARIF,
    payload: {
        data,
    }
})

export const postReloadTarif = ({query}) => ({
    type: POST_TARIF_RELOAD,
    payload: {
        data: query
    }
})
