import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader, setMaxStep, setStep} from "../../../actions/app/ui/ui.actions";
import {FETCH_CHECK_STATUT_CONTRAT} from "../../../actions/app/checkContratStatut/checkContratStatut.actions";
import {setInitSession} from "../../../actions/app/initSession/initSession.actions";
import { getSlugStep } from '../../../../utils/function'

export const checkContratStatutMiddleware = () => next => action => {
    next(action)

    const { payload } = action

    switch (action.type){

        case FETCH_CHECK_STATUT_CONTRAT:

            next([
                apiRequest({body: null, method: 'POST', url: payload.data, entity: FETCH_CHECK_STATUT_CONTRAT}),
                setLoader({state: true, entity: FETCH_CHECK_STATUT_CONTRAT})
            ])

            break

        case `${FETCH_CHECK_STATUT_CONTRAT} ${API_SUCCESS}`:

            if (payload.data.state === 'contrat') {
                /* Vérifie qu'une signature est en cours */
                let step = payload.data.signatures.length > 0 && payload.data.signatures[0].refused === false ?
                    getSlugStep('signature')
                    : getSlugStep('envoi-des-pieces');

                if(payload.data.data.source === 'courtier' || payload.data.data.source === 'conseiller'){
                    step = getSlugStep('envoi-des-pieces');
                }

                next([
                    setInitSession({init: payload.data}),
                    setStep({step: step}),
                    setMaxStep({maxStep: step}),
                    setError({state: false, entity: FETCH_CHECK_STATUT_CONTRAT}),
                    setLoader({state: false, entity: FETCH_CHECK_STATUT_CONTRAT})
                ])
            } else if (payload.data.state === 'contrat_en_attente_de_paiement' || payload.data.state === 'contrat_en_cours_de_validation') {
                next([
                    setInitSession({init: payload.data}),
                    setError({state: false, entity: FETCH_CHECK_STATUT_CONTRAT}),
                    setLoader({state: false, entity: FETCH_CHECK_STATUT_CONTRAT})
                ])
            } else {
                next([
                    setError({state: false, entity: FETCH_CHECK_STATUT_CONTRAT}),
                    setLoader({state: false, entity: FETCH_CHECK_STATUT_CONTRAT})
                ])
            }

            break

        case `${FETCH_CHECK_STATUT_CONTRAT} ${API_ERROR}`:
            next([
                setError({state: true, entity: FETCH_CHECK_STATUT_CONTRAT}),
                setLoader({state: false, entity: FETCH_CHECK_STATUT_CONTRAT})
            ])
            break

        default:
            return null
    }
}
