import {useState} from 'react';
import PropTypes from 'prop-types'
import './table-collapse.scss'

const TableCollapse = (props) => {
    const { title, children } = props
    const [collapse, setCollapse] = useState(false)

    return (
        <div className={`tableCollapseContainer cursor-pointer rounded overflow-hidden ${collapse ? 'deploy' : '' }`}>
            <div className="collapseHeader" onClick={() => setCollapse(!collapse)} data-test={'collapseHeader'}>
                <div className="row align-items-center">
                    <div className={"col-9"} data-test={'blocTitle'}>
                        <div className={'text-initial m-0 collapseTitle'} data-test={'title'}>{title}</div>
                    </div>
                    <div className={"col-3 justify-content-end d-flex align-items-center blocIcons"}>
                        {
                            children
                                ? <span className={`icon-arrow-bottom icon-arrow text-initial`} style={{fontSize: 16}} />
                                : <span className={'icon-sans-icone'}/> //Nécessaire pour la hauteur des blocs
                        }
                    </div>
                </div>
            </div>
            <div className={`collapseContent ${ collapse && children && 'border-top mt-2 pt-2'}`} data-test={'containerChildren'}>
                { collapse && children }
            </div>
        </div>
    );
};


TableCollapse.defaultProps = {};


TableCollapse.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    children: PropTypes.node
};


export default TableCollapse;
