import notes from '@amo/core/assets/images/icons/notes.svg';
import opinions from '@amo/core/assets/images/logos/opinion.svg';

const FooterLight = ({ gestionnaireIsAMO }) => {
    return (
        <>
            <div className={`footer f-13 d-lg-block`}>
                <div
                    className={`container-fluid d-lg-block d-none bg-linear`}
                >
                    <div className={'container'}>
                        <div className={'px-5'}>
                            <div className={'row align-items-center'}>
                                {gestionnaireIsAMO && (
                                    <>
                                        <div className={'col-5'}>
                                        </div>
                                        <div className={'py-5 col-4 d-flex'}>
                                            <div className={'left'}>
                                                <img src={notes} alt={'+3000votes'} />
                                                <p
                                                    className={'f-11 fw-semibold text-grey'}
                                                    style={{ marginLeft: 63 }}
                                                >
                                                    sur 3&nbsp;728 votes{' '}
                                                </p>
                                            </div>
                                            <div className={'right ms-4'}>
                                                <a
                                                    href={
                                                        'https://www.opinion-assurances.fr/assureur-april-moto.html'
                                                    }
                                                    target={'_blank'}
                                                    rel="noopener noreferrer"
                                                >
                                                    <img src={opinions} alt={'Opinions Assurances'} width={98} />
                                                </a>
                                            </div>
                                        </div>
                                    </>
                                )}
                                {!gestionnaireIsAMO && (
                                    <div className={'col-5'} style={{ height: 100 }}>
                                        &nbsp;
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`container-fluid bg-grey d-block d-lg-none d-xl-none py-4`}>
                    <div className={'row align-items-center'}>
                        {gestionnaireIsAMO && (
                            <>
                                <div className={'col-12 d-flex align-items-center justify-content-center'}>
                                    <div className={'left'}>
                                        <img src={notes} alt={'+3000votes'} />
                                        <p className={'f-11 fw-semibold text-grey'} style={{ marginLeft: 63 }}>
                                            sur 3&nbsp;728 votes{' '}
                                        </p>
                                    </div>
                                    <div className={'right ms-4'}>
                                        <a
                                            href={'https://www.opinion-assurances.fr/assureur-april-moto.html'}
                                            target={'_blank'}
                                            rel="noopener noreferrer"
                                        >
                                            <img src={opinions} alt={'Opinions Assurances'} width={98} />
                                        </a>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <div className={`container-fluid p-4 f-12 bg-white`}>
                    <div className="row footer-link ">
                        <div className="col-12 text-center">
                            {gestionnaireIsAMO && (
                                <a
                                    href={
                                        'https://www.april-moto.com/wp-content/uploads/2022/07/26.06.2022-CGU-Groupe-April.pdf'
                                    }
                                    target={'_blank'}
                                    rel="noopener noreferrer"
                                    className={'text-grey mx-4 d-block d-lg-inline-block mb-2 mb-lg-0'}
                                >
                                    Mentions légales
                                </a>
                            )}
                            <a
                                href={
                                    gestionnaireIsAMO
                                        ? 'https://www.april-moto.com/gestion-des-cookies/'
                                        : 'https://amgestionassurance.com/gestion-cookies.html'
                                }
                                target={'_blank'}
                                rel="noopener noreferrer"
                                className={'text-grey mx-4 d-block d-lg-inline-block mb-2 mb-lg-0'}
                            >
                                Gestion des cookies
                            </a>
                            <a
                                href={
                                    gestionnaireIsAMO
                                        ? 'https://www.april-moto.com/wp-content/uploads/2021/11/Lettre-RGPD-APRIL-Moto-AM21-11.pdf'
                                        : 'https://www.amgestionassurance.com/Doc/Lettre-RGPD-AM-Gestion-11.2021.pdf'
                                }
                                target={'_blank'}
                                rel="noopener noreferrer"
                                className={'text-grey mx-4 d-block d-lg-inline-block mb-2 mb-lg-0'}
                            >
                                Politique des données personnelles
                            </a>
                            <a
                                href={
                                    gestionnaireIsAMO
                                        ? 'https://assets.april.fr/prismic/documents/juridique/doc-april-traitement-reclamations.pdf?func=proxy'
                                        : 'https://amgestionassurance.com/#reclamations'
                                }
                                target={'_blank'}
                                rel="noopener noreferrer"
                                className={'text-grey mx-4 d-block d-lg-inline-block mb-2 mb-lg-0'}
                            >
                                Traitement de vos réclamations
                            </a>
                            {gestionnaireIsAMO && (
                                <a
                                    href={
                                        'https://www.april-moto.com/wp-content/uploads/2022/07/26.06.2022-Rubrique-Fonctionnement_Groupe-April.pdf'
                                    }
                                    target={'_blank'}
                                    rel="noopener noreferrer"
                                    className={'text-grey mx-4 d-block d-lg-inline-block mb-2 mb-lg-0'}
                                >
                                    Fonctionnement du Tarificateur
                                </a>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FooterLight;
