import { useState, useEffect } from 'react';
import _ from "lodash";
import { Autocomplete } from "@amo/core/components/forms";
import { getCitiesFromFrance } from '@amo/core/utils/requestsApi.js';

export function AutoCompleteBirth({setCapBirthIsDown, country, input, onChangeCityFunction= () => {}, setErrorLoadingCountries, ...props}) {
    const [listVilles, setListVilles] = useState([])
    const [queries, setQueries] = useState([]);
    const [query, setQuery] = useState('');
    const [error, setError] = useState(false);

    async function fetchCites(value) {
        try{
            let cities = await getCitiesFromFrance({value, setErrorLoadingCountries})
            if (cities.status === 500) {
                setCapBirthIsDown(true)
            } else {
                setCapBirthIsDown(false)
                if (!Array.isArray(cities)) {
                    return
                }
                setErrorLoadingCountries(false)
                let list = _.orderBy(cities, ['sInsee'], ['asc'])

                list = cities.map(c => ({
                    value: c.sBirthplace,
                    label: c.sBirthplace,
                    department: c.sDepartment
                }))

                const newVilles = _.uniqBy([...listVilles, ...list], 'label')
                setListVilles(newVilles)
            }

        } catch(error) {
            if (error.response && error.response.status === 500) {
                console.error('Error 500:', error);
                setError(true);
            } else {
                console.error('An error occurred:', error);
                setError(true);
            }
        }
    }

    useEffect(() => {
        if (query && query.length >= 2 && country === 'FRA' && !queries.includes(query)) {
            fetchCites(query)
            setQueries([...queries, query])
        }
    }, [query, country]);

    useEffect(() => {
        onChangeCityFunction(input.value, listVilles)
    }, [input.value]);

    if (country !== 'FRA' || error) {
        return setErrorLoadingCountries(true)
    }

    return (
        <Autocomplete
            data={listVilles}
            setQuery={setQuery}
            input={input}
            {...props}
        />
    )

}
