import { normalizeEuro } from '@amo/core/utils/normalize';
import _ from 'lodash';
import locker from "@amo/core/assets/images/icons/locker.png";
import paiement from "@amo/core/assets/images/icons/paiement.svg";

const Souscrire = (props) => {
    const {
        codePromo,
        data,
        prixTotalMensuel,
        prixTotalMensuelPromo, nextStep, setDevisOrSubscribe, handleSubmit,
        prixTotalAnnuel,
        prixTotalAnnuelPromo,
        prixTotal,
        prixTotalPromo, loading, isCourtier
    } = props;
    const enPromo = prixTotalPromo !== undefined && prixTotal > prixTotalPromo;

    const goDevisOrSubscribe = (form, state) => {
        setDevisOrSubscribe({state})
        nextStep(form)
    }


    return (
        <div id={'souscrire'} className={'container sticky-bottom'}>
            <div className={`row mt-4`}>
                <div className={'col-12'}>
                    <div className={'bg-white border rounded p-lg-4 py-3 px-0 row align-items-center'} style={{justifyContent: 'space-around'}}>
                        <div className="d-none d-lg-block col-12 col-lg-2 text-start mb-3 ml-5 mb-lg-0">
                            {_.get(data, 'DemandeTarif[Police][FractionnementChoisi]') === 'M' && (
                                <div className={'mois'}>
                                    {codePromo?.Eligible && prixTotalMensuelPromo < prixTotalMensuel ? (
                                        <>
                                            <span className={'f-30 fw-bold text-secondary'}>
                                                {normalizeEuro(prixTotalMensuelPromo)}
                                            </span>
                                            <small className={'f-10'}>/mois*</small>
                                            <small className={'d-block f-14 text-decoration-line-through'}>
                                                {normalizeEuro(prixTotalMensuel)}/mois*
                                            </small>
                                            <div className={`text-initial f-14 font-weight-bold`}>
                                                soit {_.round(enPromo ? prixTotalAnnuelPromo : prixTotalAnnuel, 2)} <span className="text-initial">TTC/an</span>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <span className={'f-30 fw-bold text-secondary'}>
                                                {normalizeEuro(prixTotalMensuelPromo)}
                                            </span>
                                            <small className={'f-10'}>/mois*</small>
                                            <div className={`text-initial f-14 font-weight-bold`}>
                                                soit {_.round(enPromo ? prixTotalAnnuelPromo : prixTotalAnnuel, 2)} <span className="text-initial">TTC/an</span>
                                            </div>
                                        </>
                                    )}
                                </div>
                            )}
                            <small className={'f-12'}>*Prix TTC</small>
                        </div>
                        <div className={'col-12 col-lg-4 text-center d-flex flex-column '}>
                            <button
                                className={`btn btn-primary btn-smaller btn-arrow mb-2`}
                                onClick={handleSubmit(form => goDevisOrSubscribe(form, 'subscribe'))}
                            >
                                {loading ? <span className="spinner-border spinner-border-sm" role="status"
                                                 aria-hidden="true"></span> : 'Souscrire'}
                            </button>
                            <p className={'text-grey f-12 fw-bold'}>
                                <img src={locker} alt={'Paiement sécurisé'}/>
                                Paiement sécurisé <img src={paiement} alt={'Paiement sécurisé'}/>
                            </p>
                            <button
                                className={`btn btn-secondary btn-smaller btn-arrow d-block`}
                                onClick={handleSubmit(form => goDevisOrSubscribe(form, 'devis'))}
                                style={{padding: '14px 10px'}}
                            >
                                {loading ?
                                    <span className="spinner-border spinner-border-sm" role="status"
                                          aria-hidden="true"></span>
                                    : isCourtier ? 'Obtenir la proposition' : 'Recevoir ma proposition par email'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Souscrire;
