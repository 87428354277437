import {connect} from 'react-redux'
import ErreurTarif from "./ErreurTarif";
import {getErrorTarif} from "../../../../../../../redux/selectors/ui/ui.selectors";

const mapStateToProps = state => {
    return {
        text: getErrorTarif(state)
    }
}

const ErreurTarifContainer = connect(mapStateToProps)(ErreurTarif)

export default ErreurTarifContainer
