import {Fragment} from 'react';
import {Field} from "redux-form";
import {
    maxDateToday,
    required,
    valeurVehicule,
    dateMin6Month
} from '@amo/core/utils/validateField';
import {ChampsObligatoires, QuestionLabel, SimpleTitle} from '@amo/core/components/containers'
import {DateField, Radio, Text, Checkbox} from "@amo/core/components/forms";
import velo from "@amo/core/assets/images/produits/velo.svg";
import vae from "@amo/core/assets/images/produits/vae.svg";
import cargo from "@amo/core/assets/images/produits/cargo.svg";
import {normalizeAccent, normalizeNumberOnly} from "@amo/core/utils";

const Vehicule = (props) => {
    const {handleSubmit, valid, nextStep, typeVehicule, getTexteBySource, isCourtier, unsetValue, canUseBesoin, isAvenant, isDirect, isComparateur} = props

    return (
        <div>
            <SimpleTitle><img src={velo} alt={'velo'} width={35} className={'mr-2'} /> {getTexteBySource('votre-velo')}</SimpleTitle>

            {
                isCourtier && <>
                    {
                        canUseBesoin ? <>
                                <QuestionLabel className={'f-16 mt-4'}>Souhaitez-vous utiliser le Recueil du Besoin&nbsp;?<sup className={'text-danger'}>*</sup></QuestionLabel>
                                <div className={'row'}>
                                    <div className={'col-12 col-md-4 mt-3 mt-md-4'}>
                                        <Field
                                            component={Radio}
                                            type={'radio'}
                                            name={'DemandeTarif[UseRecueilBesoin]'}
                                            value={'oui'}
                                            label={'Oui'}
                                            validate={required}
                                            className={'text-center'}
                                            onChange={() => unsetValue('DemandeTarif[DevoirConseil][DevoirIC]')}
                                        />
                                    </div>

                                    <div className={'col-12 col-md-4 mt-3 mt-md-4'}>
                                        <Field
                                            component={Radio}
                                            type={'radio'}
                                            name={'DemandeTarif[UseRecueilBesoin]'}
                                            value={'non'}
                                            label={'Non'}
                                            validate={required}
                                            className={'text-center'}
                                            onChange={() => unsetValue('DemandeTarif[DevoirConseil][DevoirIC]')}
                                        />
                                    </div>
                                </div>
                            </>
                            : <div className={'bg-grey p-4 rounded text-left mt-4'}>
                                <p><b>Le Saviez-vous ?<br/>Vous avez la possibilité d'activer le Recueil du Besoin pour obtenir une Fiche Conseil.</b></p>
                                <p>Si vous souhaitez bénéficier de ce service, vous avez la possibilité de remplir toutes les informations nécessaires à la personnalisation de la Fiche Conseil dans votre espace, rubrique "Mon profil".</p>
                                <p>Si vous préférez utiliser votre document, vous pouvez poursuivre la souscription.</p>
                            </div>
                    }
                </>
            }

            {
                isAvenant && <>
                    <QuestionLabel className={'f-16 mt-4'}>Motif de l'avenant&nbsp;?<sup className={'text-danger'}>*</sup></QuestionLabel>
                    <div className={'row'}>
                        <div className={'col-12 col-md-4 mt-3 mt-md-4'}>
                            <Field
                                component={Radio}
                                type={'radio'}
                                name={'DemandeTarif[Police][MotifAvenant]'}
                                value={'24'}
                                label={'Changement de vélo'}
                                validate={required}
                                className={'text-center'}
                            />
                        </div>

                        <div className={'col-12 col-md-4 mt-3 mt-md-4'}>
                            <Field
                                component={Radio}
                                type={'radio'}
                                name={'DemandeTarif[Police][MotifAvenant]'}
                                value={'2'}
                                label={'Changement de garanties'}
                                validate={required}
                                className={'text-center'}
                            />
                        </div>

                        <div className={'col-12 col-md-4 mt-3 mt-md-4'}>
                            <Field
                                component={Radio}
                                type={'radio'}
                                name={'DemandeTarif[Police][MotifAvenant]'}
                                value={'8'}
                                label={'Mise à jour de déclaration'}
                                validate={required}
                                className={'text-center'}
                            />
                        </div>
                    </div>
                </>
            }

            <QuestionLabel className={'f-16 mt-4'}>{getTexteBySource('typeVelo')}<sup className={'text-danger'}>*</sup></QuestionLabel>
            <div className={'row'}>
                <div className={'col-6 col-md-4 mt-3 mt-md-4'}>
                    <Field
                        component={Radio}
                        type={'radio'}
                        name={'DemandeTarif[Vehicule][GenreVehicule]'}
                        value={'VEL'}
                        label={'Vélo Classique'}
                        icon={velo}
                        validate={required}
                        className={'text-center h-100'}
                    />
                </div>
                <div className={'col-6 col-md-4 mt-3 mt-md-4'}>
                    <Field
                        component={Radio}
                        type={'radio'}
                        name={'DemandeTarif[Vehicule][GenreVehicule]'}
                        value={'VAE'}
                        label={'Vélo à Assistance Electrique (VAE)'}
                        icon={vae}
                        validate={required}
                        className={'text-center h-100'}
                    />
                </div>
                <div className={'col-6 col-md-4 mt-3 mt-md-4'}>
                    <Field
                        component={Radio}
                        type={'radio'}
                        name={'DemandeTarif[Vehicule][GenreVehicule]'}
                        value={'VELC'}
                        label={'Vélo Cargo <br/> (classique ou VAE)'}
                        icon={cargo}
                        validate={required}
                        className={'text-center h-100'}
                    />
                </div>
            </div>

            <QuestionLabel className={'f-16 mt-4'}>{getTexteBySource('marqueVelo')}<sup className={'text-danger'}>*</sup></QuestionLabel>
            <div className={'row'}>
                <div className={'col-12 col-md-6 mt-3 mt-md-6'}>
                    <Field
                        component={Text}
                        name={'DemandeTarif[Vehicule][Designation]'}
                        normalize={normalizeAccent}
                        validate={required}
                    />
                </div>
            </div>
            <p className={'m-0 text-grey'}><small>{getTexteBySource('underMarqueVelo')}</small></p>

            <QuestionLabel className={'f-16 mt-4'}>{getTexteBySource('valeurVelo')}<sup className={'text-danger'}>*</sup></QuestionLabel>
            <div className={'row'}>
                <div className={'col-12 col-md-6 mt-3 mt-md-6'}>
                    <Field
                        component={Text}
                        icon={'euro'}
                        name={'DemandeTarif[Vehicule][ValeurVehicule]'}
                        normalize={normalizeNumberOnly}
                        validate={[required, valeurVehicule]}
                    />
                </div>
            </div>
            <p className={'m-0 text-grey'}><small>{getTexteBySource('underValeurVelo')}</small></p>

            <QuestionLabel className={'f-16 mt-4'}>{getTexteBySource('dateAchatVelo')}<sup className={'text-danger'}>*</sup></QuestionLabel>
            <div className={'row'}>
                <div className={'col-12 col-md-6 mt-3 mt-md-6'}>
                    <Field
                        name={'DemandeTarif[Vehicule][DateMEC]'}
                        component={DateField}
                        placeholder={'Exemple : 22/03/2020'}
                        validate={isAvenant ? [required, maxDateToday] : [required, maxDateToday, dateMin6Month]}
                    />
                </div>
            </div>
            <p className={'m-0 text-grey'}><small>{getTexteBySource('underDateAchatVelo')}</small></p>

            {(typeVehicule === 'VAE' || typeVehicule === 'VELC') && <Fragment>
                <div className={'row'}>
                    <div className={'col-12 col-md-12 mt-3 mt-md-6'}>
                        <Field
                            name="declaration_vehicule"
                            component={Checkbox}
                            type={"checkbox"}
                            validate={[required]}
                        >
                            <QuestionLabel className={'f-16 mt-1 ml-2'}>{getTexteBySource('declarationPuissanceVelo')}<sup className={'text-danger'}>*</sup></QuestionLabel>
                        </Field>
                    </div>
                </div>
            </Fragment> }

            <div className={'row'}>
                <div className={'col-12 col-md-12 mt-3 mt-md-6'}>
                    <Field
                        name="DemandeTarif[Vehicule][UsageNonLivraison]"
                        component={Checkbox}
                        type={"checkbox"}
                        validate={[required]}
                    >
                        <QuestionLabel className={'f-16 mt-1 ml-2'}>{getTexteBySource('declarationUsageVelo')}<sup className={'text-danger'}>*</sup></QuestionLabel>
                    </Field>
                </div>
            </div>

            {
                isDirect && !isComparateur && <>
                    <div className={'row'}>
                        <div className={'col-12 col-md-12 mt-3 mt-md-6'}>
                            <Field
                                name="DemandeDevis[OptinRecontact]"
                                component={Checkbox}
                                type={"checkbox"}
                                validate={[required]}
                            >
                                <QuestionLabel className={'f-16 mt-1 ml-2'}>Afin d'être accompagné dans le cadre de ma démarche d'assurance, j'accepte d'être contacté par téléphone et/ou email<sup className={'text-danger'}>*</sup></QuestionLabel>
                            </Field>
                        </div>
                    </div>
                    <p className={'m-0 text-grey'}><small>Pour vous conseiller au mieux dans le cadre de votre démarche, vous pourrez être rappelé par un conseiller APRIL d'ADM Value (N°ORIAS : 10055724).</small></p>
                </>
            }

            <ChampsObligatoires/>

            <div className={'mt-5'}>
                <button
                    className={`${!valid && 'disabled'} btn-arrow btn btn-primary medium-rounded float-right`}
                    onClick={valid ? handleSubmit(nextStep) : null}
                    data-id="btn-next"
                >
                    CONTINUER
                </button>
            </div>
        </div>
    );
};

Vehicule.defaultProps = {};

Vehicule.propTypes = {};

export default Vehicule;
