import {combineReducers} from 'redux'
import {reducer as form} from 'redux-form'
import {initSessionReducer} from "../reducers/initSession/initSession.reducer";
import {uiReducer} from "../reducers/ui/ui.reducer"
/*import {demandeTarifReducer} from "../reducers/demandeTarif/demandeTarif.reducer";*/
import {tarifReducer} from "../reducers/tarif/tarif.reducer";

const AppReducer = combineReducers({
    form,
    ui: uiReducer,
    init: initSessionReducer,
    /*demandeTarif: demandeTarifReducer,*/
    tarif: tarifReducer
})

export default AppReducer
