import {useEffect, useState} from 'react';
import PropTypes from 'prop-types'
import {Route, Switch} from "react-router-dom";
import _ from 'lodash'
import FooterContainer from "./Footer/FooterContainer";
import './initformulaire.scss'
import DemandeRappelContainer from "./demandeRappel/DemandeRappelContainer";
import ChatBoxContainer from "./Chatbox/ChatBoxContainer";
import TopBarContainer from "./TopBar/TopBarContainer";
import { SwitchTransition, CSSTransition } from 'react-transition-group'
import SidebarContainer from "./Sidebar/SidebarContainer";
import Header from '@amo/core/components/containers/Header/Header.jsx';
import ImgAvatar from '../../../../assets/images/avatar.png'
import ImgHeader from '../../../../assets/images/headervelo.png'
import NavBarContainer from "./NavBar/NavBarContainer.js";
import { getTitleByStep, getSlugStep, getStepSlug, getTexteBySource } from '../../../../utils/function'
import {formatDataForAnalytics} from "@amo/core/utils/formatDataForAnalytics.js";

const InitFormulaire = (props) => {
    const { needRDB, isAvenant, routes, step, history, previousStep, nextStep, source, tarifLoaded, setStep, data, apporteur, analyticsSetEvent, prixTotalAnnuel, numeroContrat, match: { params: { key } } } = props
    const [etape, setEtape] = useState(null)
    const [direction, setDirection] = useState(false);
    const [formRappelAffiche, setFormRappelAffiche] = useState(false);
    const fullWidthForThisSlugs = ['votre-tarif', 'paiement', 'signature', 'validation', 'envoi-des-pieces']
    const initialValuesLoaded = data !== undefined;

    useEffect(() => {
        if(step !== etape){
            setEtape(step)
            step > etape ? setDirection('left') : setDirection('right')
            history.push(_.find(routes, ['step', step])?.path)
        }
    }, [step, history, routes, etape])

    useEffect(() => {
        if (key !== undefined && _.includes(routes, ['slug', key]) && (getSlugStep(key) < step) && (getSlugStep(key) < getSlugStep('paiement'))){
            setStep({step: getSlugStep(key)})
        }
    }, [key, setStep, step, routes])

    useEffect(() => {
        if (data !== undefined ) {
            analyticsSetEvent({
                event: 'funnelStepLoad',
                ap1: apporteur,
                datas: {
                    'funnelStepLoad': {
                        'stepName': getTitleByStep(step), //à faire
                        'parentStepName': '',
                        'currentInfo': formatDataForAnalytics('velo', {...data, yealyQuote: prixTotalAnnuel, numeroContrat: numeroContrat}),
                    }
                }
            });
        }
    }, [initialValuesLoaded, step]);

    return (
        <div className={'position-relative'} style={{minHeight: '100vh'}}>
            <TopBarContainer analyticsSetEvent={analyticsSetEvent} formRappelAffiche={formRappelAffiche} setFormRappelAffiche={setFormRappelAffiche} previousPage={previousStep} >
                <div className="col-12 col-xl-6 pt-3">
                    <NavBarContainer />
                </div>
            </TopBarContainer>

            {step === 0 && <Header product={"vélo"} imgBG={ImgHeader} avatar={ImgAvatar} source={source} step={step} />}

            <div id="formulaire" />
            <div className={`${getStepSlug(step, needRDB) === 'votre-tarif' ? '' : 'container steps-formulaire'}`}>
                <div className={'row'}>
                    <SwitchTransition>
                        <CSSTransition
                            timeout={500}
                            key={etape}
                            addEndListener={(node, done) => node.addEventListener("transitionend", done, false)}
                            classNames={`fade-${direction}`}
                        >
                            <div className={`${_.includes(fullWidthForThisSlugs, getStepSlug(getSlugStep(key))) ? 'col-12' : 'col-lg-8' }`}>
                                <Switch>
                                    {
                                        routes.map((r, i) => (
                                            <Route key={i} path={r.path}
                                                   render={(props, otherProps = {
                                                       step, previousStep, nextStep,
                                                       getTexteBySource: question => getTexteBySource(source, question)
                                                   }) => r.main(props, otherProps)}
                                            />
                                        ))
                                    }
                                </Switch>
                            </div>
                        </CSSTransition>
                    </SwitchTransition>
                    {
                        !_.includes(fullWidthForThisSlugs, getStepSlug(getSlugStep(key))) && <div className="col-lg-4 d-lg-inline-block">
                            { getStepSlug(getSlugStep(key)) === 'votre-recapitulatif' ? <div style={{position: 'sticky', top: 20}}>
                                { !isAvenant && <SidebarContainer analyticsSetEvent={analyticsSetEvent} /> }
                                    <ChatBoxContainer avatar slug={getStepSlug(getSlugStep(key))} />
                                </div>
                                : <ChatBoxContainer avatar slug={getStepSlug(getSlugStep(key))} />
                            }
                        </div>
                    }
                </div>
            </div>

            <DemandeRappelContainer formRappelAffiche={formRappelAffiche} setFormRappelAffiche={setFormRappelAffiche} />
            <FooterContainer />
        </div>
    );
};

InitFormulaire.defaultProps = {
    step: 0
};

InitFormulaire.propTypes = {
    routes: PropTypes.array
};

export default InitFormulaire;
