import {connect} from 'react-redux'
import {compose} from 'redux'
import {change, formValueSelector, getFormValues, reduxForm} from "redux-form";
import Tarif from "./Tarif";
import {loader} from "@amo/core/components/loaders";
import {API_URL_WS_FORMULAIRE} from '@amo/core/constants';
import {postTarif, TARIF} from "../../../../../../redux/actions/app/tarif/tarif.actions";
import {
    getErrorEntity,
    getLoadingEntity,
    getMaxStep,
    getPrixTotal, getPrixTotalAnnuel, getPrixTotalAnnuelPromo, getPrixTotalPromo,
    getStep
} from "../../../../../../redux/selectors/ui/ui.selectors";
import {setDevisOrSubscribe, setStep} from "../../../../../../redux/actions/app/ui/ui.actions";
import {
    getActeGestionDemandeTarifInit, getCodeBelairApporteur1Init,
    getGpcSouscriptionInit, getHonorairesApporteur, getIsDirect,
    getSource, getTauxApporteur,
    getToken
} from "../../../../../../redux/selectors/initSession/initSession.selectors";
import {
    getCodeFormulePreconiseeTarif,
    getFormuleGarantiesPCC,
    getFormuleGarantiesSelected, getHonorairesMaxiApporteurTarif,
    getInfoCodePromo,
    getListeFormulesTarif,
    getTauxApporteurMaxiApporteurTarif
} from "../../../../../../redux/selectors/tarif/tarif.selectors";
import ErreurTarifContainer from "./ErreurTarif/ErreurTarifContainer";
import {reformatGaranties} from "./reformatGaranties";
import {reformatFormules} from "./reformatFormules";

const mapStateToProps = state => {
    const selector = formValueSelector('devisVae')
    const formuleChoisie = selector(state, 'DemandeTarif[Police][FormuleChoisie]')
    const fractionnement = selector(state, 'DemandeTarif[Police][FractionnementChoisi]')

    return {
        step: getStep(state),
        vehicule: selector(state, 'DemandeTarif[Vehicule]'),
        maxStep: getMaxStep(state),
        data: getFormValues('devisVae')(state),
        values: getFormValues('devisVae')(state),
        queryFill: `${API_URL_WS_FORMULAIRE}/fill/${getToken(state)}`,
        radioGroupSelectedPCC: selector(state, 'options[PCC]'),
        listeFormules: reformatFormules(getListeFormulesTarif(state)),
        formulePreconisee: getCodeFormulePreconiseeTarif(state),
        formuleSelectionnee: formuleChoisie,
        fractionnement: fractionnement,
        query: `${API_URL_WS_FORMULAIRE}/get_tarif/${getToken(state)}`,
        loaded: getLoadingEntity(state, TARIF) === false,
        loading: getLoadingEntity(state, TARIF),
        garantiesSelected: getFormuleGarantiesSelected(state, formuleChoisie),
        garantiesPCC: reformatGaranties(getFormuleGarantiesPCC(state, formuleChoisie), fractionnement),
        codePromo: getInfoCodePromo(state),
        error: getErrorEntity(state, TARIF),
        source: getSource(state),
        codePromoInForm: selector(state, 'DemandeTarif[CodePromo][CodePromo]'),
        gpcSouscription: getGpcSouscriptionInit(state),
        isDirect: getIsDirect(state),
        isAvenant: getActeGestionDemandeTarifInit(state) === 'AV',
        honorairesMax: getHonorairesMaxiApporteurTarif(state),
        tauxApporteurMax: getTauxApporteurMaxiApporteurTarif(state),
        honoraires: getHonorairesApporteur(state),
        tauxApporteur1: getTauxApporteur(state),
        apporteur: getCodeBelairApporteur1Init(state),
        prixTotal: getPrixTotal(state),
        prixTotalAnnuel: getPrixTotalAnnuel(state),
        prixTotalAnnuelPromo: getPrixTotalAnnuelPromo(state),
        prixTotalPromo: getPrixTotalPromo(state),
    }
}


const mapDispatchToProps = {
    postTarif,
    setDevisOrSubscribe,
    changeValue: (field, value) => change('devisVae', field, value),
    setStep: (step) => setStep({step})
}


const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { query, loading, values, apporteur } = stateProps
    const { postTarif } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        reloadTarifCodePromo: () => postTarif({query, ap1: apporteur, form: values, codePromo: true}),
        load: () => !loading && postTarif({query, ap1: apporteur})
    }
}


const TarifContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader({ErrorIndicator: ErreurTarifContainer, message: "Nous préparons l'offre personnalisée"}),
    reduxForm({
        form: 'devisVae',
        destroyOnUnmount: false,
        forceUnregisterOnUnmount: true,
    }),
)(Tarif)


export default TarifContainer
