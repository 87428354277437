
import './cercle-spinner.scss'

const CercleSpinner = (props) => {
    return (
        <div className={'spinner'} {...props}>
            <div className={'cercle'}/>
        </div>
    );
};

export default CercleSpinner;