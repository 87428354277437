
import _ from 'lodash'
import TableCollapse from "./TableCollapse/TableCollapse.jsx";
import './scrolling-table.scss'
import {normalizeEuro} from '@amo/core/utils/normalize.js';


const ScrollingTable = (props) => {
    const { formule, prixTotalPromo, prixTotal, fractionnement, getTexteBySource } = props

    return (
        <div className={'scrollingTable overflow-auto'}>
            <div className="table-responsive-md">
                <table className={"table mb-0"}>
                    <thead>
                        <tr>
                            <th scope="col" className={'bg-secondary-light text-initial border-bottom-0 w-50 fw-600 '}>
                                Vos garanties
                            </th>
                            <th scope="col" className={'bg-primary text-white border-bottom-0 w-50 fw-600'}>
                                <p className={'mb-0 d-flex justify-content-between align-items-center'}>
                                    <span>{(prixTotalPromo !== null && prixTotal > prixTotalPromo) ? normalizeEuro(prixTotalPromo) : normalizeEuro(formule.PrimeMensuellePromo)} / {fractionnement === 'M' ? 'mois' : 'an'}</span>
                                </p>
                            </th>
                        </tr>
                    </thead>
                    <tbody className={'text-center'}>
                    {
                        _.map(formule.ListeGaranties, (g, i) => {
                            return g.Souscrite && <tr key={i}>
                                <th scope="row" className={'f-14 font-weight-normal text-initial text-left'}>
                                    <TableCollapse
                                        title={
                                            <div>
                                                {g.LibelleGarantie}
                                            </div>
                                        }
                                    >
                                        { g.texteDeroulant && <p dangerouslySetInnerHTML={{__html: getTexteBySource(g.texteDeroulant)}} /> }
                                    </TableCollapse>
                                </th>
                                <td className={'align-middle bg-primary-light'}>
                                    <span className={`icon-Shape text-primary-light p-2 border full-rounded border-white text-white`} style={{fontSize: 5}} />
                                </td>
                            </tr>
                        })
                    }
                    </tbody>
                </table>
            </div>
        </div>
    );
};


ScrollingTable.defaultProps = {};


ScrollingTable.propTypes = {};


export default ScrollingTable;
