import RouteStep from '../RouteStep';
import _ from "lodash";

export const getRoutesByDevice = step => {
    const index = _.findIndex(RouteStep, ['step', step])
    let routes = RouteStep

    switch (index) {
        case 0:
            routes = _.slice(RouteStep, index, 5)
            break;
        case 1:
            routes = _.slice(RouteStep, index - 1, 5)
            break;
        case RouteStep.length - 2:
            routes = _.slice(RouteStep, index - 3)
            break;
        case RouteStep.length - 1:
            routes = _.slice(RouteStep, index - 4)
            break;
        default:
            routes = _.slice(RouteStep, index - 2, index + 3)
    }

    return routes
}

export const getActiveStepByDevice = step => {
    const index = _.findIndex(RouteStep, ['step', step])
    let activeStep = step

    switch (index) {
        case 0:
            activeStep = 0
            break;
        case 1:
            activeStep = 1
            break;
        case RouteStep.length - 2:
            activeStep = 3
            break;
        case RouteStep.length - 1:
            activeStep = 4
            break;
        default:
            activeStep = 2
    }

    return activeStep
}
