import { connect } from 'react-redux';
import CodePromo from './CodePromo';
import {TARIF_RELOAD} from "../../../../../../../redux/actions/app/tarif/tarif.actions.js";
import {getLoadingEntity} from "../../../../../../../redux/selectors/ui/ui.selectors.js";

const mapStateToProps = (state) => {
    return {
        loading: getLoadingEntity(state, TARIF_RELOAD),
    };
};

const CodePromoContainer = connect(mapStateToProps)(CodePromo);

export default CodePromoContainer;
