import {connect} from 'react-redux';
import _ from 'lodash';
import Chatbox from "./Chatbox.jsx";
import chatbox from '../../../../../utils/json/chatbox.json'
import {getSource} from "../../../../../redux/selectors/initSession/initSession.selectors.js";

const mapStateToProps = (state, ownProps) => {
    const chatboxByStep = _.find(chatbox, ['slug', ownProps.slug])

    return {
        ...chatboxByStep,
        show: chatboxByStep !== undefined,
        source: getSource(state),
    }

}


const ChatBoxContainer = connect(mapStateToProps)(Chatbox)

export default ChatBoxContainer
