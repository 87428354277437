import _ from 'lodash';
import RouteStep from '../components/App/InitSession/InitFormulaire/RouteStep';
import textSource from '../utils/json/texteSource.json';

export const getSlugStep = slug => {
    const route = _.find(RouteStep, ['slug', slug]);
    return (route && route !== -1) ? route.step : '';
};
export const getStepSlug = (step, needRDB) => {
    const route = _.find(needRDB ? calculRoutesByRecueilBesoin(needRDB, RouteStep) : RouteStep, ['step', step]);
    return (route && route !== -1) ? route.slug : '';
};

export const calculRoutesByRecueilBesoin = (value, routes) => {
    let step = 0
    let cloneRoute = _.cloneDeep(routes)

    if (value === 'non') {
        cloneRoute = _.reject(cloneRoute, ['slug', 'votre-besoin'])
    }

    value !== undefined && _.forEach(cloneRoute, r => {
        r.step = step++
    })

    return cloneRoute
}

export const getTexteBySource = (source, question) => {
    let newSource = source === 'conseiller' ? 'courtier' : source
    return textSource[0][question][newSource]
}
export const getTitleByStep = (step) => {
    const route = _.find(RouteStep, ['step', step]);
    return route && route !== -1 ? route.title : '';
};
export const getStepBySlug = (slug) => {
    const route = _.find(RouteStep, ['slug', slug]);
    return route && route !== -1 ? route.step : '';
};
