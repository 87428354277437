export const getLabelByValue = value => {
    switch (value) {
        /* Type */
        case 'VEL':
            return 'Classique'
        case 'VAE':
            return 'Electrique'
        case 'VELC':
            return 'Cargo'

        default:
            return '-'
    }
}
