import {
    SET_LOADER, SET_MAINTENANCE, SET_ERROR, SET_MAX_STEP, SET_STEP, SET_INDEX_QUESTION, SET_DEVIS_OR_SUBSCRIBE,
    SET_TARIF_ERROR, SET_COUNTRIES
} from '../../actions/app/ui/ui.actions'


const initState = {
    maintenance: false,
    step: 0,
    maxStep: 0,
    question: 0,
    devisOrSubscribe: 'subscribe',
    loading: {},
    errorTarif: "",
    countries: []
}

export const uiReducer = (ui = initState, action) => {
    const { payload, meta } = action

    switch (true){

        case action.type.includes(SET_LOADER):
            return {...ui, loading: {...ui.loading, [meta]: payload}}

        case action.type.includes(SET_ERROR):
            return {...ui, error: {...ui.error, [meta]: payload}}

        case action.type.includes(SET_MAINTENANCE):
            return{...ui, maintenance : payload}

        case action.type.includes(SET_MAX_STEP):
            return{...ui, maxStep : payload}

        case action.type.includes(SET_STEP):
            return {...ui, step: payload}

        case action.type.includes(SET_INDEX_QUESTION):
            return {...ui, question: payload}

        case action.type.includes(SET_DEVIS_OR_SUBSCRIBE):
            return {...ui, devisOrSubscribe: payload}

        case action.type.includes(SET_TARIF_ERROR):
            return {...ui, errorTarif: payload}

        case action.type.includes(SET_COUNTRIES):
            return {...ui, countries: payload}

        default:
            return ui
    }
}
