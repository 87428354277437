import {connect} from 'react-redux'
import PopupDevis from "./PopupDevis";
import {
    getUrlPDFDevisInit,
    getUrlPDFFicheConseilInit,
    getEmailSouscripteurTarif, getGpcSouscriptionInit, getIsDirect
} from "../../../../../../../redux/selectors/initSession/initSession.selectors";
import {
    getUrlPDFConventionAssistanceTarif,
    getUrlPDFDispositionsGeneralesTarif, getUrlPDFDocumentInformationTarif
} from "../../../../../../../redux/selectors/tarif/tarif.selectors";

const mapStateToProps = state => {
    return {
        email: getEmailSouscripteurTarif(state),
        pdfDevis: getUrlPDFDevisInit(state),
        conventionAssistance : getUrlPDFConventionAssistanceTarif(state),
        dispositionGenerale: getUrlPDFDispositionsGeneralesTarif(state),
        documentInformation: getUrlPDFDocumentInformationTarif(state),
        ficheConseil: getUrlPDFFicheConseilInit(state),
        gpcSouscription: getGpcSouscriptionInit(state),
        isDirect: getIsDirect(state)
    }
}

const PopupDevisContainer = connect(mapStateToProps)(PopupDevis)

export default PopupDevisContainer
