import NonInclus from '@amo/core/assets/images/icons/non_inclus.svg';
import { RadioBtn } from '@amo/core/components/forms';
import { showModal } from '@amo/core/utils/functions';
import _ from 'lodash';
import { Field } from 'redux-form';
import getPriceByFractionnementOrCodePromo from '../../utilsTarif/getPriceByFractionnementOrCodePromo';
import './table.scss';

const TableFormule = (props) => {
    const { listeFormules, fractionnement, formulePreconisee, codePromo } = props;

    const buildLabelFormule = (f) => {
        const price_by_frac_or_code_promo = getPriceByFractionnementOrCodePromo(f, fractionnement);

        if (codePromo?.Eligible && price_by_frac_or_code_promo.prix > price_by_frac_or_code_promo.prixPromo) {
            return `<span class="title text-dark">${f.LibelleFormule.toUpperCase()}</span>
                    <span class='price'>${price_by_frac_or_code_promo.prixPromo} <small>TTC/${
                        fractionnement === 'M' ? 'mois' : 'an'
                    }</small>
                    <br/>
                    <span class='f-16 text-decoration-line-through'>${price_by_frac_or_code_promo.prix} <small>TTC/${
                        fractionnement === 'M' ? 'mois' : 'an'
                    }</small>
                    </span>`;
        } else {
            return `<span class="title text-dark">${f.LibelleFormule.toUpperCase()}</span><span class='price'>${
                price_by_frac_or_code_promo.prix
            } <small>TTC/${fractionnement === 'M' ? 'mois' : 'an'}</small></span>`;
        }
    };

    return (
        <div className="table-responsive w-100">
            <table className={'table'}>
                <thead>
                    <tr>
                        <th className={''}>
                            <div className={'d-inline-block w-100 text-left py-4 f-24'}>Vos garanties</div>
                        </th>
                        {listeFormules &&
                            _.map(listeFormules, (f) => (
                                <th
                                    className={`select p-5`}
                                    key={f.CodeFormule}
                                >
                                    <div className={'position-relative'}>
                                        {(f.CodeFormule === formulePreconisee) && (
                                            <div
                                                className={
                                                    'rounded-bottom position-absolute text-white bg-dark py-1 px-2 f-12'
                                                }
                                                style={{ left: '50%', top: -50, transform: 'translateX(-50%)' }}
                                            >
                                                Recommandée
                                            </div>
                                        )}
                                        <Field
                                            component={RadioBtn}
                                            type={'radio'}
                                            name={'DemandeTarif[Police][FormuleChoisie]'}
                                            value={f.CodeFormule}
                                            label={buildLabelFormule(f)}
                                            className={'text-center cursor-pointer p-3 w-100 h-100'}
                                        >
                                            <p
                                                className={`btn btn-outline-primary btn-smaller cursor-pointer f-12`}
                                            >
                                                <span className={'check-icon w-20'}></span> Ma formule
                                            </p>
                                        </Field>
                                    </div>
                                </th>
                            ))}
                    </tr>
                </thead>
                <tbody className={'text-center'}>
                    {listeFormules &&
                        !_.isEmpty(listeFormules) &&
                        _.map(
                            _.filter(_.last(listeFormules).ListeGaranties, (o) => o.Disponible === 'Inclusion'),
                            (garantie, i) => (
                                <tr key={i}>
                                    <th className={'p-4 bg-white'}>
                                        <div className="col text-left">
                                            <p
                                                className={'f-14 text-dark fw-bold d-inline mr-4'}
                                                onClick={() =>
                                                    showModal({
                                                        title: garantie.LibelleGarantie,
                                                        icon: 'info',
                                                        html: garantie.ConditionsIndemnisation,
                                                    })
                                                }
                                            >
                                                <span className="d-inline-block btn-help mr-2">?</span>
                                                {garantie.LibelleGarantie}
                                            </p>
                                        </div>
                                    </th>
                                    {listeFormules &&
                                        _.map(listeFormules, (f, j) => (
                                            <td
                                                className={`select align-middle`}
                                                key={j}
                                            >
                                                <div className="pl-0">
                                                    {_.find(
                                                        f.ListeGaranties,
                                                        (h) =>
                                                            h.CodeGarantie === garantie.CodeGarantie &&
                                                            h.Disponible === 'Inclusion',
                                                    ) ? (
                                                        <span className={'check-icon w-30'}></span>
                                                    ) : (
                                                        <img src={NonInclus} alt="Inclus" className={'align-middle'} />
                                                    )}
                                                </div>
                                            </td>
                                        ))}
                                </tr>
                            ),
                        )}
                    <tr>
                        <th className={'bg-white'}></th>
                        {listeFormules &&
                            _.map(listeFormules, (f, j) => (
                                <td
                                    className={`select align-middle`}
                                    key={j}
                                >
                                    <Field
                                        component={RadioBtn}
                                        type={'radio'}
                                        name={'DemandeTarif[Police][FormuleChoisie]'}
                                        value={f.CodeFormule}
                                        label={buildLabelFormule(f)}
                                        className={'cursor-pointer p-3 w-100 h-100'}
                                    >
                                        <p className={`btn btn-outline-primary btn-smaller cursor-pointer f-12`}>
                                            <span className={'check-icon w-20'}></span> Ma formule
                                        </p>
                                    </Field>
                                </td>
                            ))}
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default TableFormule;
