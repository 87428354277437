import {useEffect} from 'react';
import Swal from 'sweetalert2'
import _ from "lodash";
import TableauGarantiesDesktop from "./TableauGarantiesDesktop/TableauGarantiesDesktop.jsx";
import TableauGarantiesMobile from "./TableauGarantiesMobile/TableauGarantiesMobile.jsx";

const Tarif = (props) => {
    const {honoraires, tauxApporteur1, tauxApporteurMax, honorairesMax, isDirect, isAvenant, codePromoInForm, radioGroupSelectedPCC, listeFormules, formulePreconisee, formuleSelectionnee, fractionnement, changeValue, garantiesSelected, garantiesPCC, codePromo,previousStep, nextStep, handleSubmit, setDevisOrSubscribe, gpcSouscription, getTexteBySource} = props

    useEffect(() => {
        if(formulePreconisee && !formuleSelectionnee){
            changeValue('DemandeTarif[Police][FormuleChoisie]', formulePreconisee);
        }
    }, [formulePreconisee, changeValue, formuleSelectionnee])

    useEffect(() => {
        if(garantiesSelected){
            _.map(garantiesSelected, g => {
                const id = g.IdGroupeGarantie
                changeValue(`options[${id}]`, g.CodeGarantie);
            })
        }
    }, [garantiesSelected, changeValue])

    useEffect(() => {
        if(codePromoInForm && codePromo && !codePromo.Eligible && codePromo.MessageEligible){
            Swal.fire({
                icon: 'error',
                text: codePromo.MessageEligible,
                confirmButtonText: 'J\'ai compris'
            })
            changeValue('DemandeTarif[CodePromo][CodePromo]', null)
        }
    }, [codePromo])

    useEffect(() => {
        changeValue('DemandeTarif[Police][TauxApporteur1]', tauxApporteur1)
        changeValue('DemandeTarif[Police][Honoraires]', honoraires)
    }, [])

    return (
        <div>

            <TableauGarantiesDesktop {...props} />

            <TableauGarantiesMobile {...props} />
        </div>
    );
};


Tarif.defaultProps = {};


Tarif.propTypes = {};


export default Tarif;
