import { connect } from 'react-redux';
import Souscrire from './Souscrire';
import {
    getLoadingEntity,
    getPrixTotal,
    getPrixTotalAnnuel, getPrixTotalAnnuelPromo,
    getPrixTotalPromo
} from "../../../../../../../redux/selectors/ui/ui.selectors.js";
import {FILL} from "../../../../../../../redux/actions/app/fill/fill.actions.js";
import {getIsCourtier} from "../../../../../../../redux/selectors/initSession/initSession.selectors.js";

const mapStateToProps = (state) => {
    return {
        prixTotal: getPrixTotal(state),
        prixTotalMensuel: getPrixTotal(state),
        prixTotalMensuelPromo: getPrixTotalPromo(state),
        prixTotalAnnuel: getPrixTotalAnnuel(state),
        prixTotalAnnuelPromo: getPrixTotalAnnuelPromo(state),
        prixTotalPromo: getPrixTotalPromo(state),
        loading: getLoadingEntity(state, FILL),
        isCourtier: getIsCourtier(state)
    };
};

const SouscrireContainer = connect(mapStateToProps)(Souscrire);

export default SouscrireContainer;
