import Depanneuse from '@amo/core/assets/images/AMO_Icons/Depanneuse.svg';
import Protection from '@amo/core/assets/images/AMO_Icons/Protection.svg';
import SearchVehicule from '@amo/core/assets/images/AMO_Icons/SearchVehicule.svg';
import ToutMotards from '@amo/core/assets/images/AMO_Icons/Tout-motards.svg';
import Indemnisation from '@amo/core/assets/images/AMO_Icons/indemnisation.svg';

export const getTextOptions = (codeOption, apporteur1) => {
    switch (codeOption) {

        case 'PCC' /* Protection corporelle du conducteur */:
            return `<p><b>La seule garantie qui couvre l'utilisateur du vélo et l'enfant transporté !</b></p><br/><p>Parce qu’une chute en vélo n’est pas toujours sans conséquence, nous vous recommandons de souscrire la garantie Protection corporelle du cycliste.
<br/><br/>Avec la Protection corporelle du cycliste, nous indemnisons les préjudices subis suite à un accident corporel, que vous soyez responsable ou non !
</p><br/><p><b class="text-dark les_plus"><span>Les + ${
                apporteur1 === 43397 ? 'APRIL Moto :' : ''
            }</span></b>Et quel que soit votre taux d'invalidité (< 15%), nous prenons en charge vos frais médicaux et votre perte de revenus, jusqu'à 1 500 € !
<br/>Pour l'enfant transporté et accidenté, nous prenons en charge jusqu'à 40 h d'école à domicile afin qu'il puisse continuer à suivre son programme scolaire.</p>`;
        default:
            return null;
    }
};

export const getIconOptions = (codeOption) => {
    switch (codeOption) {
        case 'ASS' /* Assistance */:
            return Depanneuse;
        case 'PCC' /* Protection corporelle du conducteur */:
            return Protection;
        case 'ACCESSEQP' /* Accessoires et équipements */:
            return ToutMotards;
        case 'KSM' /* Casse mécanique */:
            return SearchVehicule;
        case 'VALMAJ' /* Valeur majorée */:
            return Indemnisation;
        default:
            return null;
    }
};
