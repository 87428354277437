import './header.scss'
import { Avatar } from '@amo/core/components/containers'

const Header = ({source, step, avatar, imgBG, product}) => {
    return (
        <div id="header" className={'header'} style={{background: `url('${imgBG}') no-repeat center`}}>
            <div className={'text-center m-auto position-relative container'}>
                <div className={'row'}>
                    <div className={'col-md-6 offset-md-3 bg-white  bg-white '}>
                        <p className="header-title">Assurance {product}</p>
                        <p className={'header-subtitle'}>Demande de devis</p>

                        <div className={'col-md-10 offset-md-1'}>
                            <Avatar direction={"top"} img={avatar} text={source === 'site' ? '<em>Vous êtes au bon endroit pour vous assurer au meilleur prix&nbsp;!</em>' : '<em>Vous êtes au bon endroit pour assurer votre client au meilleur prix&nbsp;!</em>'} />
                        </div>
                    </div>
                    {step === 0 &&
                        <span className={'col-12 btn-start'}>
                            <span className={'btn btn-primary medium-rounded mt-4 mt-md-0 w-100'} onClick={() => document.getElementById('formulaire').scrollIntoView({behavior: 'smooth'})} data-id="btn-start">C'est parti !</span>
                        </span>
                    }
                </div>
            </div>
        </div>
    )
}

export default Header
