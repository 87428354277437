import EnvoiPiecesDirect from "./EnvoiPiecesDirect/EnvoiPiecesDirect";
import EnvoiPiecesIndirectContainer from "./EnvoiPiecesIndirect/EnvoiPiecesIndirectContainer";

const EnvoiPieces = (props) => {
    const { source } = props

    return (
        <div>
            {
                (source === 'conseiller' || source === 'courtier') ? <EnvoiPiecesIndirectContainer {...props}/> : <EnvoiPiecesDirect {...props} />
            }
        </div>
    );
};

export default EnvoiPieces;
