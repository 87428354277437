import {connect} from 'react-redux'
import Sidebar from "./Sidebar";
import {formValueSelector, getFormValues} from "redux-form";
import {
    getCodeBelairApporteur1Init,
    getIsDirect
} from "../../../../../redux/selectors/initSession/initSession.selectors";
import {setStep} from "../../../../../redux/actions/app/ui/ui.actions";
import {getListCountries} from "../../../../../redux/selectors/ui/ui.selectors";

const mapStateToProps = state => {
    const selector = formValueSelector('devisVae')

    return {
        values: getFormValues('devisVae')(state),
        useBesoin: selector(state, 'DemandeTarif[UseRecueilBesoin]'),
        isDirect: getIsDirect(state),
        countries: getListCountries(state),
        apporteur: getCodeBelairApporteur1Init(state),
    }
}

const mapDispatchToProps = {
    setStep
}

const SidebarContainer = connect(mapStateToProps, mapDispatchToProps)(Sidebar)

export default SidebarContainer
