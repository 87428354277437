import {connect} from 'react-redux'
import EnvoiPieces from "./EnvoiPieces";
import {
    getDocumentsUrlInit,
    getListeDocumentsContratInit, getNameWhitelabel,
    getSource
} from "../../../../../../redux/selectors/initSession/initSession.selectors";

const mapStateToProps = state => {
    return {
        documentsUrl: getDocumentsUrlInit(state),
        listeDocuments: getListeDocumentsContratInit(state),
        source: getSource(state),
        nameWhitelabel: getNameWhitelabel(state)
    }
}

const EnvoiPiecesContainer = connect(mapStateToProps)(EnvoiPieces)

export default EnvoiPiecesContainer
