import {useEffect, useState} from 'react';
import _ from 'lodash'
import CercleSpinner from "./CercleSpinner/CercleSpinner";


const StatusContrat = (props) => {
    const {checkContratStatut, etapes_paiement, contratState, id_devis} = props
    const [secondesAvtRefresh, setSecondesAvtRefresh] = useState(60);

    useEffect(() => {
        let interval = null;

        if (secondesAvtRefresh>0) {
            interval = setInterval(() => {
                setSecondesAvtRefresh(seconds => seconds - 1);
            }, 1000);
        } else{
            checkContratStatut()
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [secondesAvtRefresh, checkContratStatut]);

    return (
        <div>
            <h1 className="title h2 mb-4">Contrat en { contratState === 'contrat_en_attente_de_paiement' ? 'attente de paiement' : 'cours d\'enregistrement'}...</h1>
            <div className={'mb-4'}>Devis {id_devis}</div>

            {
                contratState === 'contrat_en_attente_de_paiement' &&
                <div className={'mb-4 font-weight-bold'}>Attention, le contrat ne sera pas validé si le paiement n'est pas finalisé dans les 48h.</div>
            }

            <div className={'my-5 border-bottom'}>
                {
                    etapes_paiement && _.map(etapes_paiement, (etape, index) => (
                        <div key={index} className={'d-flex py-3 border-top align-items-center'}>
                            { etape.date ? <span className={`icon-Shape checkSuccess mediumBlocIcons border full-rounded border-success d-inline-block position-relative align-middle`} style={{fontSize: 15}} /> : <CercleSpinner/>}
                            <span className={'d-inline-block ml-4'}>{etape.name}</span>
                        </div>
                    ))
                }
            </div>


            <div className={'text-center mb-5'}>
                <button className={`btn btn-primary medium-rounded btn-smaller`} onClick={checkContratStatut}>
                    {secondesAvtRefresh>0 ? `Actualiser (${secondesAvtRefresh})` : 'Actualisation...'}
                </button>
            </div>
        </div>
    );
};

StatusContrat.defaultProps = {};

StatusContrat.propTypes = {};

export default StatusContrat;
