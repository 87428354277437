export const ANALYTICS = '[Analytics]'
export const ANALYTICS_SET_EVENT = `${ANALYTICS} Set Event`;

export const SET_ANALYTICS = `${ANALYTICS} Set`

export const analyticsSetEvent = ({ event, ap1, datas = null }) => ({
  type: ANALYTICS_SET_EVENT,
  meta: {
    analytics: {
      event,
      ap1,
      datas
    },
  },
});

/* Plus utilisé suite refonte */
export const setAnalytics = ({entity}) => ({
  type: `${entity} ${SET_ANALYTICS}`,
  payload : {
    entity
  }
})

/* Plus utilisé suite refonte */
export const setTrackPage = ({page}) => ({
  type: `${ANALYTICS} ${page}`,
  meta: {
    entity: ANALYTICS,
    analytics: {
      event: 'Page View',
      actionGoogle: page
    }
  }
})
