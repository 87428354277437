import {connect} from 'react-redux'
import Paiement from "./Paiement";
import {
    getIsDirect, getIsSendMailPaiement,
    getLibelleFormuleDevisInit,
    getToken
} from "../../../../../../redux/selectors/initSession/initSession.selectors";
import {API_URL_WS_FORMULAIRE, APP_URL_APPLICATION} from '@amo/core/constants';
import {formValueSelector, getFormValues} from "redux-form";
import {postPay} from "../../../../../../redux/actions/app/pay/pay.action";

const mapStateToProps = state => {
    const selector = formValueSelector('devisVae')
    return {
        formule: getLibelleFormuleDevisInit(state),
        velo: selector(state, 'DemandeTarif[Vehicule][Designation]'),
        url_pay: {
            uri: `${API_URL_WS_FORMULAIRE}/pay/${getToken(state)}`,
            token: getToken(state),
            url_return: `${APP_URL_APPLICATION}/paiement`
        },
        isDirect: getIsDirect(state),
        sendMailPaiement: getIsSendMailPaiement(state),
        form: getFormValues('devisVae')(state),
    }
}

const mapDispatchToProps = {
    postPay
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { url_pay, form } = stateProps
    const { postPay } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        postPay: () => postPay({query: url_pay.uri, form, url_pay}),
    }
}

const PaiementContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(Paiement)

export default PaiementContainer
