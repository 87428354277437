import {createSelector} from 'reselect'
import _ from 'lodash'
import {getTarifInit} from "../initSession/initSession.selectors";

/* Tarif */
const tarifSelector = state => state.tarif

export const getPoliceTarif = createSelector(
    tarifSelector,
    tarif => tarif.Police || {}
)

export const isLoadedTarif = createSelector(
    tarifSelector,
    tarif => !_.isEmpty(tarif)
)

/* Liste garanties par formule */
const formuleSelectedSelector = (state, code) => _.find(state.tarif.ListeFormules, ['CodeFormule', code]) || {}

/* Liste Formules */
export const getListeFormulesTarif = createSelector(
    tarifSelector,
    tarif => _.orderBy(tarif.ListeFormules, ['CodeFormule'], ['asc']) || []
)

/* Formule Preconisee */
export const getCodeFormulePreconiseeTarif = createSelector(
    tarifSelector,
    tarif => tarif.FormulePreconisee
)
export const getFormulePreconiseeTarif = createSelector(
    getListeFormulesTarif,
    getCodeFormulePreconiseeTarif,
    (liste, code) => _.find(liste, ['CodeFormule', code])
)

/* CODE PROMO */
export const getInfoCodePromo = createSelector(
    tarifSelector,
    tarif => tarif.CodePromo
)

/* URL PDF */
export const getUrlPDFConventionAssistanceTarif = createSelector(
    tarifSelector,
    tarif => tarif.UrlPDFConventionAssistance
)

export const getUrlPDFDispositionsGeneralesTarif = createSelector(
    tarifSelector,
    tarif => tarif.UrlPDFDispositionsGenerales
)

export const getUrlPDFDocumentInformationTarif = createSelector(
    tarifSelector,
    tarif => tarif.UrlPDFDocumentInformation
)

export const getHonorairesMaxiApporteurTarif = createSelector(
    getPoliceTarif,
    police => police.HonorairesMaxi ?? 20
)

export const getTauxApporteurMaxiApporteurTarif = createSelector(
    getPoliceTarif,
    police => police.TauxApporteur1Maxi ?? 20
)

export const getGarantiesOption = createSelector(
    formuleSelectedSelector,
    formule => _.orderBy(_.filter(formule.ListeGaranties, { TypeGarantie: 'Option', Disponible: 'Option' }), ['OrdreAffichage'], ['asc'])
)
export const getFormuleLibelle = createSelector(
    formuleSelectedSelector,
    (formule) => formule.LibelleFormule
)
export const getFormuleGarantiesPCC = createSelector(
    getGarantiesOption,
    garanties => _.filter(garanties, { IdGroupeGarantie: 'PCC' })
)

export const getFormuleGarantiesSelected = createSelector(
    formuleSelectedSelector,
    (formule) => {
        if(formule){
            return _.filter(formule.ListeGaranties, function(garantie) {
                return (garantie.TypeGarantie === 'Option' && garantie.Disponible === 'Option' && garantie.Souscrite);
            })
        }

        return null;
    }
)
