import {API_URL_WS_FORMULAIRE} from '@amo/core/constants';

export const INIT = '[InitSession]'

export const SET_INIT = `${INIT} Set`
export const POST_INIT = `${INIT} Post`
export const SET_DEMANDE_TARIF_AFTER_GET_TARIF = `${INIT} Set DemandeTarif after Get Tarif`

export const postInitSession = ({token, key, body}) => ({
    type: POST_INIT,
    payload: {
        data: `${API_URL_WS_FORMULAIRE}/init`,
        body: { token, key, ...body }
    }
})

export const setInitSession = ({init}) => ({
    type: SET_INIT,
    payload: {
        data: init
    }
})

export const setDemandeTarifAfterGetTarif = ({data}) => ({
    type: SET_DEMANDE_TARIF_AFTER_GET_TARIF,
    payload: {
        data
    }
})
