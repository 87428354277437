import {connect} from 'react-redux'
import Signature from "./Signature";
import {
    getAmountPaymentsInit,
    getFormuleDevisInit, getGenreVehiculeVehiculeInit, getNameWhitelabel,
    getPoliceContractInit, getSignatureEmailDemandeContratInit, getSignaturePhoneDemandeContratInit,
    getUrlSignaturesInit
} from "../../../../../../redux/selectors/initSession/initSession.selectors";
import {APP_URL_APPLICATION} from '@amo/core/constants';

const mapStateToProps = state => {

    return {
        url: `${getUrlSignaturesInit(state)}?url_return=${APP_URL_APPLICATION}`,
        police: getPoliceContractInit(state),
        formule: getFormuleDevisInit(state),
        genreVehicule: getGenreVehiculeVehiculeInit(state),
        amount: getAmountPaymentsInit(state),
        signaturePhone: getSignaturePhoneDemandeContratInit(state),
        signatureEmail: getSignatureEmailDemandeContratInit(state),
        nameWhitelabel: getNameWhitelabel(state)
    }
}

const SignatureContainer = connect(mapStateToProps)(Signature);

export default SignatureContainer
