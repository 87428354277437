const Maintenance = () => {
    return (
        <div
            style={{
                margin: 'auto',
                width: '500px',
                'text-align': 'center',
                'margin-top': '100px',
            }}
        >
            <h1>Votre formulaire est en cours de maintenance.</h1>

            <div className={'row'}>
                <div className={'col'}>
                    Avatar
                    <div>
                        <p>Salut, c'est Johann Zarco&nbsp;!</p>
                        <p>
                            <span>Nos équipes techniques font le maximum pour rétablir votre formulaire</span>, nous
                            vous remercions de votre compréhension.
                        </p>
                    </div>
                </div>
            </div>

            <a href="/">Réessayer</a>
        </div>
    );
};

export default Maintenance;
