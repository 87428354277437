import PropTypes from 'prop-types'
import './chatbox.scss'
import { Avatar } from '@amo/core/components/containers';
import imgAvatar from '../../../../../assets/images/avatar.png'
import header from '../../../../../assets/images/headervelo.png'

const Chatbox = (props) => {
    const { avatar, title, content, highlight, secondContent, imgContent, show, source } = props
    const collectionImg = {header}
    let s = source === 'conseiller' ? 'courtier' : source

    return (
        <>
            {
                show && <div id={"chatboxcontainer"} className={'mt-5 mt-lg-0'}>
                    <div id="chatbox" className={"bg-secondary-light "}>
                        { title && <h2 className={"text-initial"} dangerouslySetInnerHTML={{ __html: title }} /> }
                        { content[s] && <p className={"text-initial content mb-3"} dangerouslySetInnerHTML={{ __html: content[s] }} /> }
                        { collectionImg[imgContent] && <img src={collectionImg[imgContent]} className={'img-fluid mb-3'} alt={"image-chatbox"} /> }
                        { highlight && <blockquote className={"text-primary-light font-weight-bold"} dangerouslySetInnerHTML={{ __html: highlight }} /> }
                        { secondContent && <p className={"text-initial content mt-3"} dangerouslySetInnerHTML={{ __html: secondContent }} /> }
                    </div>

                    { avatar && <Avatar img={imgAvatar} /> }
                </div>
            }
        </>
    );
};


Chatbox.defaultProps = {
    avatar: false,
    show: false
};


Chatbox.propTypes = {
    title: PropTypes.string,
    content: PropTypes.object,
    highlight: PropTypes.string,
    secondContent: PropTypes.string,
    avatar: PropTypes.bool,
    show: PropTypes.bool.isRequired
};


export default Chatbox;
