import _ from "lodash";
import optionsTextes from "../../../../../../utils/json/options.json"

export const reformatGaranties = (garantiesFormule, fractionnement) => {
    if(garantiesFormule.length > 0){
        const idGroupe = _.head(garantiesFormule).IdGroupeGarantie;
        const textesGroupe = optionsTextes[idGroupe];

        const listeGarantiesSelectable = _.map(garantiesFormule, g => {
            const text_garantie = textesGroupe && textesGroupe.options ? _.find(textesGroupe.options, ['id', g.CodeGarantie]) : null;
            let listeTarifOption = null

            return {
                listeTarifOption: listeTarifOption,
                value: g.CodeGarantie,
                label: g.LibelleBouton,
                dataAffichage: {
                    ...text_garantie,
                    titre: g.LibelleGarantie,
                    prix: g.PrimeClientMensuelle,
                    prix_promo: g.PrimeClientMensuellePromo,
                }
            };
        })
        if(listeGarantiesSelectable.length > 0) {
            return {
                ...textesGroupe,
                idGroupe    : idGroupe,
                labelGroupe : _.head(garantiesFormule).LibelleGroupeGarantie,
                data        : listeGarantiesSelectable,
            }
        }
    }

    return [];
}
