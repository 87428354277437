import bracketBottom from '@amo/core/assets/images/icons/bracket-bottom.svg';
import { BlocOption } from '@amo/core/components/group-form';
import _ from 'lodash';
import { useState } from 'react';
import { getIconOptions, getTextOptions } from './getTextOptions';
import './options.scss';

const Options = (props) => {
    const { garantiesPCC, fractionnement, changeValue, data, codePromo } = props;
    let garantiePCCUpdate = garantiesPCC

    if(!_.isEmpty(garantiePCCUpdate)){
        garantiePCCUpdate.data[0].dataAffichage.text = "<p><b>CETTE GARANTIE VOUS INDEMNISE :</b></p><br/><p>en cas d'invalidité (≥15%) : jusqu'à 100 000 €</p><p>en cas de décès : 15 000 € pour votre conjoint et 3 000 € par enfant mineur</p><p>Sachez que tout utilisateur de votre vélo (famille, amis) sera également assuré par cette garantie, ainsi que l'enfant transporté (sur un siège vélo, dans une remorque ou caisse d'un vélo cargo...).</p><br/><p><b>LES + APRIL MOTO :</b></p><br/><p>Et quel que soit votre taux d'invalidité (< 15%), nous prenons en charge vos frais médicaux et votre perte de revenus, jusqu'à 1 500 € !</p><p>Pour l'enfant transporté et accidenté, nous prenons en charge jusqu'à 40h d'école à domicile afin qu'il puisse continuer à suivre son programme scolaire.</p>"
    }

    const options = [garantiePCCUpdate]
    const [height, setHeight] = useState(
        options.filter((element) => element && (Array.isArray(element) ? element.length : true)).length > 3
            ? 400
            : 'none',
    );

    return (
        <div id={'liste-options'}>
            <div className={`row mt-4 ${height !== 'none' && 'bg-gradient-bottom'}`} style={{ maxHeight: height }}>
                <p className={'f-20 text-dark fw-bold text-center mb-3 col-12 title-option my-lg-4'}>
                    Complétez votre couverture avec des options sur-mesure&nbsp;:
                </p>

                {_.map(options, (option, index) => {
                    if (!option.labelGroupe) {
                        return null;
                    }
                    return (
                        <div className={'content-bloc-option m-auto'}>
                            <BlocOption
                                goodForMe={false}
                                from={false}
                                idGroupe={option.idGroupe}
                                key={index}
                                icon={getIconOptions(option.idGroupe)}
                                title={option.labelGroupe}
                                fractionnement={fractionnement}
                                name={`options[${option.idGroupe}]`}
                                data={option.data}
                                changeValue={changeValue}
                                selected={_.get(
                                    data, `options[${option.idGroupe}]`,
                                )}
                                text={getTextOptions(option.idGroupe, 43397)}
                                codePromo={codePromo}
                            />
                        </div>
                    );
                })}
            </div>

            <div className="mb-5">
                {height !== 'none' ? (
                    <span className={'btn btn-outline-primary fw-normal px-3 py-2'} onClick={() => setHeight('none')}>
                        Voir plus d'options <img src={bracketBottom} alt=">" />
                    </span>
                ) : options.filter((element) => element && (Array.isArray(element) ? element.length : true)).length >
                  3 ? (
                    <span
                        className={'btn btn-outline-primary fw-normal px-3 py-2'}
                        onClick={() => {
                            setHeight(400);
                            document.getElementById('liste-options').scrollIntoView({ behavior: 'smooth' });
                        }}
                    >
                        Voir moins d'options
                    </span>
                ) : (
                    ''
                )}
            </div>
        </div>
    );
};

export default Options;
