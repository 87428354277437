import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {change, clearFields, formValueSelector, getFormValues, reduxForm} from "redux-form";
import Coordonnees from "./Coordonnees";
import {
    getDevisOrSubscribe,
    getErrorEntity, getListCountries,
    getLoadingEntity,
    getStep
} from "../../../../../../redux/selectors/ui/ui.selectors";
import {postSaveDevis, SAVE_DEVIS} from "../../../../../../redux/actions/app/saveDevis/saveDevis.actions";
import {API_URL_WS_FORMULAIRE} from '@amo/core/constants';
import {
    getIsDirect,
    getSource,
    getToken, getEmailVenteApporteurInit, getIsMB, getActeGestionDemandeTarifInit
} from "../../../../../../redux/selectors/initSession/initSession.selectors";
import {loader} from "@amo/core/components/loaders";
import {setCountries} from "../../../../../../redux/actions/app/ui/ui.actions";

const mapStateToProps = state => {
    const selector = formValueSelector('devisVae')
    const ville = selector(state, 'DemandeTarif[ListePersonnes][0][Ville]')
    const cp = selector(state, 'DemandeTarif[ListePersonnes][0][CP]')

    return {
        data: getFormValues('devisVae')(state),
        postalCodeCity: ville ? `${ville} ${cp}` : '',
        civilitePrincipale: selector(state, 'civilitePrincipale'),
        isAvenant: getActeGestionDemandeTarifInit(state) === 'AV',
        conducteur: selector(state, 'DemandeTarif[ListePersonnes][0]'),
        addressNotFound: selector(state, 'addressNotFound'),
        step: getStep(state),
        devisOrSubscribe: getDevisOrSubscribe(state),
        query: `${API_URL_WS_FORMULAIRE}/save_devis/${getToken(state)}`,
        saveDevisLoading: getLoadingEntity(state, SAVE_DEVIS),
        saveDevisError: getErrorEntity(state, SAVE_DEVIS),
        source: getSource(state),
        isDirect: getIsDirect(state),
        loaded: getLoadingEntity(state, SAVE_DEVIS) !== true,
        loading: getLoadingEntity(state, SAVE_DEVIS),
        emailCourtier: getEmailVenteApporteurInit(state),
        isMB: getIsMB(state),
        email: selector(state, 'DemandeTarif[ListePersonnes][0][Email]'),
        emailValidation: selector(state, 'EmailValidation'),
        emailOnError: selector(state, 'emailOnError'),
        countries: getListCountries(state),
        birthCountry: selector(state, 'DemandeTarif[ListePersonnes][0][BirthCountry]'),
        cityBirth: selector(state, 'DemandeTarif[ListePersonnes][0][BirthCity]'),
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    changeValue: (field, value) => change('devisVae', field, value),
    unsetValue: (field) => clearFields('devisVae', false, false, field),
    postSaveDevis,
    setCountries
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { step, query, devisOrSubscribe } = stateProps
    const { postSaveDevis } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        postSaveDevis: (form) => postSaveDevis({form, query, devisOrSubscribe, step})
    }
}

const CoordonneesContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'devisVae',
        destroyOnUnmount: false,
        forceUnregisterOnUnmount: true,
    }),
    loader()
)(Coordonnees)

export default CoordonneesContainer
