import TarifContainer from "./Steps/Tarif/TarifContainer";
import VehiculeContainer from "./Steps/Vehicule/VehiculeContainer";
import CoordonneesContainer from "./Steps/Coordonnees/CoordonneesContainer";
import BesoinContainer from "./Steps/Besoin/BesoinContainer";
import PaiementContainer from "./Steps/Paiement/PaiementContainer";
import ValidationContainer from "./Steps/Validation/ValidationContainer";
import EnvoiPiecesContainer from "./Steps/EnvoiPieces/EnvoiPiecesContainer";
import SignatureContainer from "./Steps/Signature/SignatureContainer";
import RecapitulatifContainer from "./Steps/Recapitulatif/RecapitulatifContainer";

let step = 0;

const RouteStep = [
    {
        step: step++,
        slug: 'votre-velo',
        title: 'Votre vélo',
        path: '/votre-velo',
        main: (props, otherProps) => <VehiculeContainer {...props} {...otherProps} />
    },
    {
        step: step++,
        slug: 'votre-besoin',
        title: 'Votre besoin',
        path: '/votre-besoin',
        main: (props, otherProps) => <BesoinContainer {...props} {...otherProps} />
    },
    {
        step: step++,
        slug: 'votre-tarif',
        title: 'Votre tarif',
        path: '/votre-tarif',
        main: (props, otherProps) => <TarifContainer {...props} {...otherProps} />
    },
    {
        step: step++,
        slug: 'vos-coordonnees',
        title: 'Vos coordonnées',
        path: '/vos-coordonnees',
        main: (props, otherProps) => <CoordonneesContainer {...props} {...otherProps} />
    },
    {
        step: step++,
        slug: 'votre-recapitulatif',
        title: 'Votre récapitulatif',
        path: '/votre-recapitulatif',
        main: (props, otherProps) => <RecapitulatifContainer {...props} {...otherProps} />
    },
    {
        step: step++,
        slug: 'paiement',
        title: 'Paiement',
        path: '/paiement',
        main: (props, otherProps) => <PaiementContainer {...props} {...otherProps} />
    },
    {
        step: step++,
        slug: 'validation',
        title: 'Validation',
        path: '/validation',
        main: (props, otherProps) => <ValidationContainer {...props} {...otherProps} />
    },
    {
        step: step++,
        slug: 'signature',
        title: 'Signature',
        path: '/signature',
        main: (props, otherProps) => <SignatureContainer {...props} {...otherProps} />
    },
    {
        step: step++,
        slug: 'envoi-des-pieces',
        title: 'Envoi des pièces',
        path: '/envoi-des-pieces',
        main: (props, otherProps) => <EnvoiPiecesContainer {...props} {...otherProps} />
    }
]

export default RouteStep
