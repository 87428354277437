import _ from 'lodash'

/**
 * creer un clone du formulaire pour y faire des modifications de champs pour l'API
 * @param body
 * @returns {*}
 */
export const formatDataForApi = body => {
    let cloneBody = _.cloneDeep(body);

    stringToBool(cloneBody);
    stringToInt(cloneBody);
    dateToString(cloneBody);
    removePermisNull(cloneBody);
    removeSpace(cloneBody);
    removeHyphen(cloneBody);
    reformatGarantiesSelected(cloneBody);
    reIndexArray(cloneBody);
    //dateHourToString(cloneBody);
    cleanListePersonnes(cloneBody);

    return cloneBody
};

/**
 * retire les espaces dans les champs
 * @param clone
 */
const removeSpace = clone => {
    const fields = [
        'DemandeTarif[ListePersonnes][0][TelPortable]',
        'DemandeContrat[SignaturePhone]'
    ]

    _.forEach(fields, f => {
        _.get(clone, f) && _.set(clone, f, _.get(clone, f).replace(/\s/g, ''))
    })
}

/**
 * convertie les champs qui ont 'true' ou 'false' en boolean
 * @param clone
 */
const stringToBool = clone => {
    const booleans = [
        'DemandeTarif[DevoirConseil][DevoirVol]',
        'DemandeDevis[OptinUtilisationDonnees]',
        'DemandeDevis[OptinNewsletter]'
    ];

    const returnBool = string => string === 'true';

    _.forEach(booleans, b => {
        _.get(clone, b) && _.set(clone, b, returnBool(_.get(clone, b)))
    })
}

/**
 * convertie les champs string en integer
 * @param clone
 */
const stringToInt = clone => {
    const strings = [

    ];

    _.forEach(strings, s => {
        _.get(clone, s) && _.set(clone, s, Number(_.get(clone, s)))
    })
}

/**
 * convertie les champs de date moment en string dd/mm/aaaa
 * @param clone
 */
const dateToString = clone => {
    const dates = [
        'DemandeTarif[Vehicule][DateMEC]',
        'DemandeTarif[ListePersonnes][0][DateNaissance]',
        'DemandeContrat[TiersPayeur][BirthDate]'
    ];

    _.forEach(dates, d => {
        _.get(clone, d) && _.set(clone, d, _.get(clone, d).format('L'))
    })
}

/**
 * convertie les champs de date moment en string dd/mm/aaaa hh:mm
 * @param clone
 */
const dateHourToString = clone => {
    const dates = [
        'DemandeContrat[DateHeureEffet]'
    ];

    _.forEach(dates, d => {
        _.get(clone, d) && _.set(clone, d, _.get(clone, d).format('DD/MM/YYYY HH:mm'))
    })
}

/**
 * Retire les permis qui ont un TypePermis à null
 * @param clone
 */
const removePermisNull = clone => {
    const conducteur = clone.DemandeTarif.ListePersonnes;

    conducteur && conducteur[0] && _.remove(conducteur[0].ListePermis, p => {
        return p && (p.TypePermis === null || p.TypePermis === undefined)
    })
};

/**
 * Réindex les array qui ont un index undefined
 * @param clone
 */
const reIndexArray = clone => {
    const arrays = [

    ]

    _.forEach(arrays, a => {
        _.get(clone, a) && _.set(clone, a, _.filter(_.get(clone, a), item => item !== undefined))
    })
}

const reformatGarantiesSelected = clone => {
    let ListeOptions = {};

    //options ex {ASS: "ASS3", KSM: "KSM", PCC: "PCC1"}
    if(clone.options && _.size(clone.options) > 0) {
        ListeOptions = _.map(_.filter(clone.options, o => o && o !== ''), o => {
            return {
                'CodeOption': o,
                'Souscrite': true
            };
        })
    }

    //Pour les options liste (ACCESSEQP) il faut passer l'option selectionnée
    // optionsListe ex {ACCESSEQP: "10000"}
    if(clone.optionsListe && _.size(clone.optionsListe) > 0) {
        const listeL = _.map(clone.optionsListe, (val, key) => {
            return {
                'CodeOption': key,
                'Souscrite': val !== undefined,
                'DetailOption': val !== undefined ? val : null
            };
        })
        if(listeL.length>0){
            ListeOptions = _.concat(ListeOptions, listeL);
        }
    }

    // optionsFranchise ex {RAFRADTA: false, RAFRAVI: true}
    if(clone.optionsFranchise && _.size(clone.optionsFranchise) > 0) {
        const listeF = _.map(clone.optionsFranchise, (val, key) => {
            return {
                'CodeOption': key,
                'Souscrite': val === true
            };
        })

        if(listeF.length>0){
            ListeOptions = _.concat(ListeOptions, listeF);
        }
    }

    clone.DemandeTarif.ListeOptions = ListeOptions
}

/**
 * retire les tirets dans les champs
 * @param clone
 */
const removeHyphen = clone => {
    const fields = [
        'DemandeContrat[IBAN]'
    ]

    _.forEach(fields, f => {
        _.get(clone, f) && _.set(clone, f, _.get(clone, f).replaceAll('-', ''))
    })
}



/**
 * On vide les personnes vides
 * Si une deuxième personne est définie c'est que cette personne est souscripteur non conducteur (tuteur ou société)
 * On duplique les informations de la personne 0 => 1
 * @param clone
 */
const cleanListePersonnes = clone => {
    const fieldToCopy = ['Adresse1', 'Adresse2', 'Adresse3', 'CP', 'Ville', 'TelPortable', 'Email', 'NumeroClient'];
    clone.DemandeTarif.ListePersonnes[0].Souscripteur = true;

    _.remove(clone.DemandeTarif.ListePersonnes, p => {
        return p === undefined || p === null
    })

    if(clone.DemandeTarif.ListePersonnes[1]) {
        clone.DemandeTarif.ListePersonnes[0].Souscripteur = false;
        clone.DemandeTarif.ListePersonnes[1].Souscripteur = true;
        clone.DemandeTarif.ListePersonnes[1].RoleConducteur = 'N';

        _.forEach(fieldToCopy, f => {
            clone.DemandeTarif.ListePersonnes[1][f] = clone.DemandeTarif.ListePersonnes[0][f];
        })
    }
}
