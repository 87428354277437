import './sidebar.scss'
import _ from 'lodash'
import {getLabelByValue} from "./helperSidebar";
import RouteStep from '../RouteStep'

const Sidebar = (props) => {
    const {values, useBesoin, isDirect, setStep, countries, analyticsSetEvent, apporteur} = props

    return (
        <div id={"sidebar"} className={'bg-grey mb-3 p-4'}>
            <p className={'subtitle mb-1'}><b>{isDirect ? 'Votre vélo' : 'Son vélo' }</b> <a href="#" onClick={() => {analyticsSetEvent({event: 'clicModifVelo', ap1: apporteur, datas: {'clicModifVelo': {'label' : 'Votre vélo'}}}); setStep({step: _.find(RouteStep, ['slug', 'votre-velo']).step})} } style={{fontSize: "13px", float:'right'}}>Modifier <i className="icon-pen" /></a></p>

            <p className={'m-0'}>Type de vélo&nbsp;: <b>{getLabelByValue(_.get(values, 'DemandeTarif[Vehicule][GenreVehicule]'))}</b></p>
            <p className={'m-0'}>Marque du vélo&nbsp;: <b>{_.get(values, 'DemandeTarif[Vehicule][Designation]')}</b></p>
            <p className={'m-0'}>Valeur du vélo&nbsp;: <b>{_.get(values, 'DemandeTarif[Vehicule][ValeurVehicule]')}</b></p>
            <p className={'m-0'}>Date d'achat du vélo&nbsp;: <b>{_.get(values, 'DemandeTarif[Vehicule][DateMEC]').format('L')}</b></p>

            {
                useBesoin !== 'non' && <>
                    <p className={'subtitle mt-4 mb-1'}><b>{isDirect ? 'Votre besoin' : 'Son besoin' }</b> <a href="#" onClick={() => {analyticsSetEvent({event: 'clicModifVelo', ap1: apporteur, datas: {'clicModifVelo': {'label' : 'Votre besoin'}}}); setStep({step: _.find(RouteStep, ['slug', 'votre-besoin']).step})} } style={{fontSize: "13px", float:'right'}}>Modifier <i className="icon-pen" /></a></p>

                    <p className={'m-0'}>Souhait d'indemnisation en cas d'accident corporel responsable&nbsp;: <b>{_.get(values, 'DemandeTarif[DevoirConseil][DevoirIC]') === "1" ? "Oui" : "Non"}</b></p>
                </>
            }

            <p className={'subtitle mt-4 mb-1'}><b>{isDirect ? 'Vos coordonnées' : 'Ses coordonnées' }</b> <a href="#" onClick={() => {analyticsSetEvent({event: 'clicModifVelo', ap1: apporteur, datas: {'clicModifVelo': {'label' : 'Vos coordonnées'}}}); setStep({step: _.find(RouteStep, ['slug', 'vos-coordonnees']).step})} } style={{fontSize: "13px", float:'right'}}>Modifier <i className="icon-pen" /></a></p>

            <p className={'m-0'}>Civilité&nbsp;: <b>{_.get(values, "DemandeTarif[ListePersonnes][0][Civilite]") === '1' ? 'Madame' : 'Monsieur'}</b></p>
            <p className={'m-0'}>Nom d'usage&nbsp;: <b>{_.get(values, "DemandeTarif[ListePersonnes][0][Nom]")}</b></p>
            <p className={'m-0'}>Nom de naissance&nbsp;: <b>{_.get(values, "DemandeTarif[ListePersonnes][0][BirthName]")}</b></p>
            <p className={'m-0'}>Prénom&nbsp;: <b>{_.get(values, "DemandeTarif[ListePersonnes][0][Prenom]")}</b></p>
            <p className={'m-0'}>Date de naissance&nbsp;: <b>{_.get(values, "DemandeTarif[ListePersonnes][0][DateNaissance]")?.format('L')}</b></p>
            <p className={'m-0'}>Pays de naissance&nbsp;: <b>{_.get(values, "DemandeTarif[ListePersonnes][0][BirthCountry]") ? _.find(countries, ['id', values.DemandeTarif['ListePersonnes'][0]['BirthCountry']])?.name : null}</b></p>
            <p className={'m-0'}>Ville de naissance&nbsp;: <b>{_.get(values, "DemandeTarif[ListePersonnes][0][BirthCity]")}</b></p>
            <p className={'m-0'}>Adresse&nbsp;: <b>{_.get(values, "DemandeTarif[ListePersonnes][0][Adresse1]")}</b></p>
            { _.get(values, "DemandeTarif[ListePersonnes][0][Adresse2]") && <p className={'m-0'}>Complément d'adresse&nbsp;: <b>{_.get(values, "DemandeTarif[ListePersonnes][0][Adresse2]")}</b></p> }
            <p className={'m-0'}>Ville, Code postal&nbsp;: <b>{`${_.get(values, "DemandeContrat[villeIBAN]")}, ${_.get(values, "DemandeContrat[cpIBAN]")}`}</b></p>
            <p className={'m-0'}>Numéro de téléphone&nbsp;: <b>{_.get(values, "DemandeTarif[ListePersonnes][0][TelPortable]")}</b></p>
            <p className={'m-0'}>Email&nbsp;: <b>{_.get(values, "DemandeTarif[ListePersonnes][0][Email]")}</b></p>
            <p className={'m-0'}>Consentement d'information des nouvelles offres&nbsp;: <b>{_.get(values, "DemandeDevis[OptinNewsletter]") === 'true' ? "Oui" : "Non"}</b></p>
            <p className={'m-0'}>Consentement de publicité d'offre d'assurance&nbsp;: <b>{_.get(values, "DemandeDevis[OptinUtilisationDonnees]") === 'true' ? "Oui" : "Non"}</b></p>
        </div>
    );
};

export default Sidebar;
