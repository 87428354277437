import {useEffect} from 'react';
import {LargeSpinner} from '@amo/core/components/loaders'

const Validation = (props) => {
    const { query, fetchContratStatut } = props

    useEffect(() => {
        const timer = setTimeout(() => fetchContratStatut({query}), 5000)
        return () => clearTimeout(timer)
    })

    return ( <LargeSpinner message={"Votre souscription est en cours de validation..."} /> );
};

export default Validation;
