import {BrowserRouter, Switch, Route} from 'react-router-dom';
import InitSessionContainer from "./InitSession/InitSessionContainer";
import Maintenance from '@amo/core/pages/maintenance/Maintenance.jsx';

const App = () => {
    return (
        <div>
            <BrowserRouter>
                <Switch>
                    <Route exact path='/maintenance' component={Maintenance} />
                    <Route exact path='/:key?' component={InitSessionContainer} />
                </Switch>
            </BrowserRouter>
        </div>
    )
}

export default App
