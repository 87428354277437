import {SimpleTitle} from "@amo/core/components/containers";

const EnvoiPiecesIndirect = (props) => {
    const { ficheConseil, hamon, mandatSepa, contrat, documentInformation, dispositionGenerale, documentsUrl, source} = props

    return (
        <div>
            <div>
                <div className={'bg-secondary-light p-4 mb-5 text-center rounded'}>
                    <div className={'f-21 text-primary-light mb-3'}>
                        <span className={`icon-triangle d-inline-block position-relative mr-2`} style={{fontSize: 30}} />
                        Il vous reste des documents à nous faire parvenir.
                    </div>
                    <div className={'f-18'}>
                        Un email vient d'être envoyé à votre client pour la signature électronique.
                    </div>
                    <div className={'f-18'}>
                        Une fois celle-ci finalisée, vous recevrez les documents signés par email.
                    </div>
                </div>
            </div>

            <div className={'text-center'}>
                <SimpleTitle icon={'document'} className={'mb-4'}>Téléchargez immédiatement les documents pour votre client :</SimpleTitle>

                <div>
                    {contrat && <div><a href={contrat} className={'btn btn-primary medium-rounded text-uppercase mb-3'} target={'_blank'} rel="noreferrer">Dispositions Particulières</a></div>}
                    {mandatSepa && <div><a href={mandatSepa} className={'btn btn-primary medium-rounded text-uppercase mb-3'} target={'_blank'} rel="noreferrer">Mandat SEPA</a></div>}
                    {ficheConseil && <div><a href={ficheConseil} className={'btn btn-primary medium-rounded text-uppercase mb-3'} target={'_blank'} rel="noreferrer">Fiche conseil</a></div>}
                    {hamon && <div><a href={hamon} className={'btn btn-primary medium-rounded text-uppercase mb-3'} target={'_blank'} rel="noreferrer">Mandat de résiliation Hamon</a></div>}

                    <ul className={'list-unstyled'}>
                        {documentInformation && <li><a href={documentInformation} target={'_blank'} rel="noreferrer">Document d'information</a></li>}
                        {dispositionGenerale && <li><a href={dispositionGenerale} target={'_blank'} rel="noreferrer">Dispositions Générales</a></li>}
                    </ul>
                </div>
            </div>

            {
                source === 'courtier' && <div className={'text-center mt-5'}>
                    <SimpleTitle icon={'document'} className={'mb-4'}>Envoyez-nous les pièces justificatives de votre client</SimpleTitle>
                    <div className={'f-18 mb-3'}>
                        Afin de valider au plus vite le contrat de votre client, merci de nous transmettre l'ensemble des documents demandés via la plateforme sécurisée.
                    </div>

                    <div className="text-center">
                        <a href={documentsUrl} className={'btn btn-primary medium-rounded text-uppercase mb-3'}>Envoyer les documents</a>
                    </div>
                </div>
            }
        </div>
    );
};


export default EnvoiPiecesIndirect;
