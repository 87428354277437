import {connect} from 'react-redux'
import EnvoiPiecesIndirect from "./EnvoiPiecesIndirect";
import {
    getUrlPDFContratSepaContratInit, getUrlPDFDispositionsGeneralesTarifInit, getUrlPDFDocumentInformationTarifInit,
    getUrlPDFFicheConseilInit,
    getUrlPDFHamonContratInit, getUrlPDFMandatSepaContratInit
} from "../../../../../../../redux/selectors/initSession/initSession.selectors";

const mapStateToProps = state => {
    return {
        ficheConseil: getUrlPDFFicheConseilInit(state),
        hamon: getUrlPDFHamonContratInit(state),
        mandatSepa: getUrlPDFMandatSepaContratInit(state),
        contrat: getUrlPDFContratSepaContratInit(state),
        dispositionGenerale: getUrlPDFDispositionsGeneralesTarifInit(state),
        documentInformation: getUrlPDFDocumentInformationTarifInit(state),
    }
}

const EnvoiPiecesIndirectContainer = connect(mapStateToProps)(EnvoiPiecesIndirect)


export default EnvoiPiecesIndirectContainer