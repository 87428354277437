import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setIndexQuestion, setLoader, setMaxStep, setStep} from '../../../actions/app/ui/ui.actions'
import {POST_FILL, FILL} from "../../../actions/app/fill/fill.actions";
import {formatDataForApi} from "./formatDataForApi";
import {setNotification} from "../../../actions/core/notifications/notifications.actions";
import {analyticsSetEvent} from "../../../actions/core/analytics/analytics.actions.js";

export const fillMiddleware = ({dispatch}) => next => action => {
    next(action)

    const { payload } = action
    let nextActions = []

    switch (action.type){

        case POST_FILL:

            const body = formatDataForApi(payload.body)

             next([
                apiRequest({body, method: 'POST', url: payload.data, entity: FILL, otherData: payload.meta.otherData}),
                setLoader({state: true, entity: FILL})
            ])

            break

        case `${FILL} ${API_SUCCESS}`:
            const {step, maxStep, url} = payload.meta.otherData

            if (url){
                return window.location = url
            }

            window.scroll(0,0)

            nextActions = [
                setStep({step}),
                setIndexQuestion({index: 0}),
                setError({state: false, entity: FILL}),
                setLoader({state: false, entity: FILL})
            ]

           if (step > maxStep){
               nextActions.push(setMaxStep({maxStep: step}))
           }

            next(nextActions)

            break

        case `${FILL} ${API_ERROR}`:
            next([
                analyticsSetEvent({event: 'errorMessage', ap1: payload?.meta?.otherData?.ap1, datas: {
                    'errorMessage': {'label': action.payload?.data?.response?.data?.error}
                }}),
                setError({state: true, entity: FILL}),
                setLoader({state: false, entity: FILL}),
                setNotification({entity: FILL, title: 'Attention', confirmButtonText: 'Continuer', icon: 'error', text: payload.data.response.data.error})
            ])
            break

        default:
            return null
    }
}
