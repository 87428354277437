const CancelPaiement = (props) => {
    const {previousStep, velo, formule, url_pay, postPay} = props

    return (
        <div>
            <div className={'bg-secondary-light p-4 mb-5 text-center rounded'}>
                <div className={'f-21 text-primary-light mb-3'}>
                    <span className={`icon-triangle d-inline-block position-relative mr-2`} style={{fontSize: 30}} />
                    Votre souscription est en attente de paiement.
                </div>

                <div className={'f-21 mb-3'}>
                    Vous êtes à deux pas de finaliser la souscription<br/>pour votre vélo <b>{velo}</b> avec la formule <b>{formule}</b>&nbsp;!
                </div>

                <div className={'f-18'}>
                    Un espace de paiement sécurisé plus rapide et sécurisée&nbsp;!
                </div>
            </div>

            <span
                className={`btn btn-return me-3`}
                onClick={() => previousStep()}
            >
                RETOUR
            </span>

            <form action={url_pay.uri} method={'POST'} className={'d-inline'}>
                <input type="text" name='token' value={url_pay.token} hidden readOnly />
                <input type="text" name='url_return' value={url_pay.url_return} hidden readOnly />
                <input type="text" name='redirection' value={false} hidden readOnly />
            </form>

            <button
                className={`btn btn-primary medium-rounded float-right`}
                data-id="btn-souscription"
                onClick={() => postPay()}
            >
                JE FINALISE LA SOUSCRIPTION IMMEDIATEMENT
            </button>
        </div>
    );
};

export default CancelPaiement;
