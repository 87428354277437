import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import Besoin from "./Besoin";
import {clearFields, reduxForm} from "redux-form";
import {getSource} from "../../../../../../redux/selectors/initSession/initSession.selectors";

const mapStateToProps = state => {
    return {
        isCourtier: getSource(state) === 'courtier'
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    unsetValue: (field) => clearFields('devisVae', false, false, field),
}, dispatch)

const BesoinContainer = compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: 'devisVae',
        destroyOnUnmount: false,
        forceUnregisterOnUnmount: true,
    })
)(Besoin)

export default BesoinContainer
