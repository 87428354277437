import _ from 'lodash'

/**
 * creer un clone du formulaire pour y faire des modifications de champs pour l'API
 * @param body
 * @returns {*}
 */
export const formatDataForApi = body => {
    let cloneBody = _.cloneDeep(body);

    dateToString(cloneBody);
    removeSpace(cloneBody)

    reIndexArray(cloneBody);

    return cloneBody
};

/**
 * retire les espaces dans les champs
 * @param clone
 */
const removeSpace = clone => {
    const fields = [
        'tel'
    ]

    _.forEach(fields, f => {
        _.get(clone, f) && _.set(clone, f, _.get(clone, f).replace(/\s/g, ''))
    })
}

/**
 * convertie les champs de date moment en string dd/mm/aaaa
 * @param clone
 */
const dateToString = clone => {
    const dates = [
        'date',
    ];

    _.forEach(dates, d => {
        _.get(clone, d) && _.set(clone, d, _.get(clone, d).format('L'))
    })
}

/**
 * Réindex les array qui ont un index undefined
 * @param clone
 */
const reIndexArray = clone => {
    const arrays = [
        'DemandeTarif[ListePersonnes][0][ListePermis]'
    ]

    _.forEach(arrays, a => {
        _.get(clone, a) && _.set(clone, a, _.filter(_.get(clone, a), item => item !== undefined))
    })
}
