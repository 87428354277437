import _ from "lodash";
import options from "../../../../../../utils/json/options.json"

export const reformatFormules = formules => {
    if(formules.length > 0){

        return _.map(formules, f => {

            return {
                ...f,
                ListeGaranties : _.map(_.orderBy(f.ListeGaranties, ['OrdreAffichage'], ['asc']), g => {
                    const textesGroupe = options[g.IdGroupeGarantie];
                    return {
                        ...g,
                        ...textesGroupe
                    };
                })
            }
        })
    }

    return [];
}