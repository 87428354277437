import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import _ from 'lodash'
import InitSession from './IniSession'
import {loader} from "@amo/core/components/loaders";
import {getError, getErrorEntity, getLoadingEntity} from "../../../redux/selectors/ui/ui.selectors";
import {INIT, postInitSession} from "../../../redux/actions/app/initSession/initSession.actions";
import {localStorageAMO} from "../../../redux/selectors/localStorage/localStorage.selectors";
import RouteStep from "./InitFormulaire/RouteStep";
import { APP_NAME } from '@amo/core/constants';
import { getSlugStep } from '../../../utils/function';

const mapStateToProps = state => {
    return {
        loaded: getLoadingEntity(state, INIT) === false,
        error: getError(state) && getErrorEntity(state, INIT),
        stepTarif: getSlugStep('votre-tarif'),
    }
}

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
    load: () => postInitSession({
        token: localStorageAMO && localStorageAMO.token,
        key: !_.find(RouteStep, ['slug', ownProps.match.params.key]) ?
            ownProps.match.params.key
            : JSON.parse(window.localStorage.getItem(`${APP_NAME}-source`)) && ((localStorageAMO && !localStorageAMO.token) || !localStorageAMO) ?
                JSON.parse(window.localStorage.getItem(`${APP_NAME}-source`)).key
                : null
    })
}, dispatch)


const InitSessionContainer = compose(
    connect(mapStateToProps, mapDispatchToProps),
    loader()
)(InitSession)

export default InitSessionContainer
