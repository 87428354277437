import {SET_TARIF} from "../../actions/app/tarif/tarif.actions";

export const tarifReducer = (tarif = {}, action) => {
    const {payload} = action

    switch (action.type) {

        case SET_TARIF:
            return payload.data

        default:
            return tarif

    }
}
