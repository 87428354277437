import {createSelector} from 'reselect'
import {getInfoCodePromo, getListeFormulesTarif} from "../tarif/tarif.selectors";
import _ from "lodash";

/* UI */
const uiSelector = state => state.ui
const formDevisSelector = state => state.form.devisVae.values

/* Maintenance */
export const getMaintenance = createSelector(
    uiSelector,
    ui => ui.maintenance
)

/* Loading */
const loadingEntitySelector = (state, entity) => state.ui.loading[entity]

export const getLoading = createSelector(
    uiSelector,
    ui => ui.loading
)

export const getLoadingEntity = createSelector(
    loadingEntitySelector,
    loading => loading
)

/* ErrorStyled */
const errorEntitySelector = (state, entity) => state.ui.error[entity]

export const getErrorEntity = createSelector(
    errorEntitySelector,
    error => error
)

export const getError = createSelector(
    uiSelector,
    ui => ui.error
)

/* Step */
export const getStep = createSelector(
    uiSelector,
    ui => ui.step
)

/* Max Step */
export const getMaxStep = createSelector(
    uiSelector,
    ui => ui.maxStep
)

/* Index QuestionLabel */
export const getIndexQuestion = createSelector(
    uiSelector,
    ui => ui.question
)

/* Get Devis info or Subscribe Info */
export const getDevisOrSubscribe = createSelector(
    uiSelector,
    ui => ui.devisOrSubscribe
)

/* Error Tarif */
export const getErrorTarif = createSelector(
    uiSelector,
    ui => ui.errorTarif
)

/*  Gestion du prix total et prix total promo de la page tarif (gestion des garanties sélectionnées) */

export const getPrix = createSelector(
    formDevisSelector,
    getListeFormulesTarif,
    getInfoCodePromo,
    (formDevis, listeFormules, infosPromo) => {
        const formuleChoisie = _.find(listeFormules, ['CodeFormule', formDevis.DemandeTarif.Police.FormuleChoisie]) || {};
        const fractionnement = formDevis.DemandeTarif.Police.FractionnementChoisi || {};
        let price = null;
        let price_promo = null;
        let annual_price = null;
        let annual_price_promo = null;

        if(formuleChoisie.PrimeMensuelleBase){
            price = formuleChoisie.PrimeMensuelleBase;
            price_promo = formuleChoisie.PrimeMensuelleBasePromo;
        }


        // if(fractionnement === "M" && formuleChoisie.PrimeMensuelleBase){
        //     price = formuleChoisie.PrimeMensuelleBase;
        //     price_promo = formuleChoisie.PrimeMensuelleBasePromo;
        // } else if(fractionnement === "A" && formuleChoisie.PrimeAnnuelleFracAnnuelBase){
        //     price = formuleChoisie.PrimeAnnuelleFracAnnuelBase;
        //     price_promo = formuleChoisie.PrimeAnnuelleFracAnnuelBasePromo;
        // }

        if(price !== null){

            // formDevis.options ex {ASS: "ASS3", KSM: "KSM", PCC: "PCC1"}
            if(formDevis.options){
                _.forEach(formDevis.options, function(optionSelected) {
                    const optionValide = _.find(formuleChoisie.ListeGaranties,  { 'CodeGarantie': optionSelected, 'Disponible': "Option" });

                    if(optionValide){
                        price += fractionnement === "M" ? optionValide.PrimeClientMensuelle : optionValide.PrimeClient;
                        price_promo += fractionnement === "M" ? optionValide.PrimeClientMensuellePromo : optionValide.PrimeClientPromo;
                    }
                });
            }

            // formDevis.optionsListe ex {ACCESSEQP: "10000"}
            if(formDevis.optionsListe) {
                _.forEach(formDevis.optionsListe, function (sousOptionSelected, optionSelected) {
                    const optionValide = _.find(formuleChoisie.ListeGaranties, {'CodeGarantie': optionSelected, 'Disponible': "Option"});
                    const sousOptionValide = optionValide && _.find(optionValide.listeTarifOption, {'DetailOption': sousOptionSelected});

                    if (sousOptionValide) {
                        price += fractionnement === "M" ? sousOptionValide.PrimeClientMensuelle : sousOptionValide.PrimeClient;
                        price_promo += fractionnement === "M" ? sousOptionValide.PrimeClientMensuellePromo : sousOptionValide.PrimeClientPromo;
                    }
                });
            }

            annual_price = _.round(price, 2)*12;
            annual_price_promo = _.round(price_promo, 2)*12;
        }

        price_promo = infosPromo && infosPromo.Eligible ? price_promo : price;
        annual_price_promo = infosPromo && infosPromo.Eligible ? annual_price_promo : annual_price;

        return {
            'price' : price,
            'price_promo' : price_promo,
            'annual_price' : annual_price,
            'annual_price_promo' : annual_price_promo,
        }
    }
)

export const getPrixTotal = createSelector(
    getPrix,
    prices => prices.price
)

export const getPrixTotalPromo = createSelector(
    getPrix,
    prices => prices.price_promo
)

export const getPrixTotalAnnuel = createSelector(
    getPrix,
    prices => prices.annual_price
)

export const getPrixTotalAnnuelPromo = createSelector(
    getPrix,
    prices => prices.annual_price_promo
)

export const getListCountries = createSelector(
    uiSelector,
    ui => ui.countries
)
