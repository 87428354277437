import {initSessionMiddleware} from "./initSession/initSession.middleware";
import {fillMiddleware} from "./fill/fill.middleware";
import {saveDevisMiddleware} from "./saveDevis/saveDevis.middleware";
import {checkContratStatutMiddleware} from "./checkContratStatut/checkContratStatut.middleware";
import {tarifMiddleware} from "./tarif/tarif.middleware";
import {payMiddleware} from "./pay/pay.middleware";
import {demandeRappelMiddleware} from "./demandeRappel/demandeRappel.middleware";

export const appMiddleware = [
    initSessionMiddleware,
    fillMiddleware,
    payMiddleware,
    saveDevisMiddleware,
    checkContratStatutMiddleware,
    tarifMiddleware,
    demandeRappelMiddleware
]
