export const PAY = '[Pay]'

export const POST_PAY = `${PAY} Post`

export const postPay = ({query, form, url_pay}) => ({
    type: POST_PAY,
    payload: {
        data: query,
        body: {...form, ...url_pay}
    },
})