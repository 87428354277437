import {Fragment, useEffect, useState} from 'react';
import _ from 'lodash'
import {Field} from "redux-form";
import {Text, Radio, Checkbox, DateField, Autocomplete, AutoCompleteBirth, CAPAdresseTrottinetteVae} from "@amo/core/components/forms";
import {
    excludeCodePostal,
    required,
    validateEmail,
    maxDateToday,
    min18Year,
    validateEmailDifferente,
    validatePhone, onError
} from '@amo/core/utils/validateField';
import {ChampsObligatoires, QuestionLabel, SimpleTitle} from '@amo/core/components/containers'
import { PostalCodeCity } from '@amo/core/components/forms';
import { normalizeTel } from '@amo/core/utils/normalize.js'
import PopupDevisContainer from "./PopupDevis/PopupDevisContainer";
import {getAddress, getCitiesFromFrance, getCountries} from '@amo/core/utils/requestsApi.js';

const Coordonnees = (props) => {
    const {
        data,
        isMB,
        handleSubmit,
        postSaveDevis,
        changeValue,
        conducteur,
        postalCodeCity,
        civilitePrincipale,
        devisOrSubscribe,
        valid,
        previousStep,
        saveDevisLoading,
        saveDevisError,
        getTexteBySource,
        isDirect,
        source,
        emailCourtier,
        addressNotFound,
        isAvenant,
        email,
        emailValidation,
        emailOnError,
        countries,
        setCountries,
        birthCountry,
        cityBirth,
        unsetValue
    } = props

    const [hasSearchAddress, setHasSearchAddress] = useState(false)
    const [errorLoadingCountries, setErrorLoadingCountries] = useState(false)
    const [capbirthIsDown, setCapBirthIsDown] = useState(false);

    const changeCivilitePrincipale = civilite => {
        if (civilite === '3') {
            changeValue('DemandeTarif[ListePersonnes][1][Civilite]', civilite)
            changeValue('DemandeTarif[ListePersonnes][1][TypePersonne]', 'M')
        } else {
            //Si on est en particulier on vide les champs liés à une société
            changeValue('DemandeTarif[ListePersonnes]', [{...conducteur}])
            changeValue('DemandeTarif[ListePersonnes][0][Civilite]', civilite)
        }
    }

    useEffect(() => {
        changeValue('DemandeContrat[cpIBAN]', conducteur.CP)
        changeValue('DemandeContrat[villeIBAN]', conducteur.Ville)
        changeValue('DemandeTarif[Vehicule][CodePostalGarage]', conducteur.CP)
        changeValue('DemandeTarif[Vehicule][VilleGarage]', conducteur.Ville)
        if (!isDirect) {
            changeValue('DemandeDevis[EmailCourtier]', emailCourtier)
        }
    }, [conducteur.CP, conducteur.Ville, emailCourtier, changeValue, isDirect])

    useEffect(() => {
        (async function fetchCountries() {
            let countriesFormat
            let countries = await getCountries()

            countriesFormat = countries.map((country) => ({
                value: country.id,
                label: country.name,
            }));

            setCountries({countries: countriesFormat})
        })();
    }, [])

    useEffect(() => {
        changeValue('emailOnError', email && emailValidation && email !== emailValidation ? 'onError' : null);
    }, [email, emailValidation])


    const myPostSaveDevis = form => {
        postSaveDevis(form)
        if (hasSearchAddress) {
            getAddress(_.get(form, 'DemandeTarif[ListePersonnes][0][Adresse1]'), "ValidateForm")
        }
        if (birthCountry === 'FRA' && cityBirth) {
            getCitiesFromFrance(cityBirth, 'ValidateFormBirth')
        }
    }

    return (
        <div>
            <SimpleTitle icon={'work'}>{getTexteBySource('titreInfo')}</SimpleTitle>

            <QuestionLabel className={'f-18 mt-4 d-block'}>{getTexteBySource('vos-coordonnees')}</QuestionLabel>

            <QuestionLabel className={'f-16 mt-4'}>Civilité<sup className={'text-danger'}>*</sup></QuestionLabel>

            <div className="row">
                <div className="col-6">
                    <Field
                        type={'radio'}
                        className={`small option`}
                        label={'Madame'}
                        value={'1'}
                        name={'civilitePrincipale'}
                        onChange={e => changeCivilitePrincipale(e.target.value)}
                        component={Radio}
                        validate={required}
                    />
                </div>
                <div className="col-6">
                    <Field
                        type={'radio'}
                        className={`small option`}
                        label={'Monsieur'}
                        value={'0'}
                        name={'civilitePrincipale'}
                        onChange={e => changeCivilitePrincipale(e.target.value)}
                        component={Radio}
                        validate={required}
                    />
                </div>


            </div>

            {
                civilitePrincipale && civilitePrincipale === '3' &&
                <>
                    <QuestionLabel className={'f-16 mt-4'}>Raison sociale<sup
                        className={'text-danger'}>*</sup></QuestionLabel>
                    <div className={'row'}>
                        <div className={'col-12 col-md-6 mt-3 mt-md-6'}>
                            <Field
                                component={Text}
                                name={'DemandeTarif[ListePersonnes][1][Nom]'}
                                normalize={v => v.toUpperCase()}
                                validate={required}
                            />
                        </div>
                    </div>

                    <QuestionLabel className={'f-16 mt-4'}>Civilité du conducteur<sup className={'text-danger'}>*</sup></QuestionLabel>
                    <div className="col-6">
                        <Field
                            type={'radio'}
                            className={`small option`}
                            label={'Madame'}
                            value={'1'}
                            name={'DemandeTarif[ListePersonnes][0][Civilite]'}
                            component={Radio}
                            validate={required}
                        />
                    </div>
                    <div className="col-6">
                        <Field
                            type={'radio'}
                            className={`small option`}
                            label={'Monsieur'}
                            value={'0'}
                            name={'DemandeTarif[ListePersonnes][0][Civilite]'}
                            component={Radio}
                            validate={required}
                        />
                    </div>
                </>
            }

            <QuestionLabel className={'f-16 mt-4'}>Nom d'usage{civilitePrincipale === '3' ? ' du conducteur' : ''}<sup
                className={'text-danger'}>*</sup></QuestionLabel>
            <div className={'row'}>
                <div className={'col-12 col-md-6 mt-3 mt-md-6'}>
                    <Field
                        component={Text}
                        name={'DemandeTarif[ListePersonnes][0][Nom]'}
                        normalize={v => v.toUpperCase()}
                        validate={required}
                        onChange={e => {
                            changeValue('DemandeContrat[NomTitulaire]', _.toUpper(`${e.target.value}${conducteur.Prenom ? ` ${conducteur.Prenom}` : ''}`))
                        }}
                    />
                </div>
            </div>

            <QuestionLabel className={'f-16 mt-4'}>Nom de
                naissance{civilitePrincipale === '3' ? ' du conducteur' : ''}{!isAvenant &&
                    <sup className={'text-danger'}>*</sup>}</QuestionLabel>
            <div className={'row'}>
                <div className={'col-12 col-md-6 mt-3 mt-md-6'}>
                    <Field
                        component={Text}
                        name={'DemandeTarif[ListePersonnes][0][BirthName]'}
                        normalize={v => v.toUpperCase()}
                        validate={!isAvenant ? required : null}
                    />
                </div>
            </div>

            <QuestionLabel className={'f-16 mt-4'}>Prénom{civilitePrincipale === '3' ? ' du conducteur' : ''}<sup
                className={'text-danger'}>*</sup></QuestionLabel>
            <div className={'row'}>
                <div className={'col-12 col-md-6 mt-3 mt-md-6'}>
                    <Field
                        component={Text}
                        name={'DemandeTarif[ListePersonnes][0][Prenom]'}
                        normalize={v => v.toUpperCase()}
                        validate={required}
                        onChange={e => changeValue('DemandeContrat[NomTitulaire]', _.toUpper(`${conducteur.Nom ? `${conducteur.Nom} ` : ''}${e.target.value}`))}
                    />
                </div>
            </div>

            {
                (
                    data?.DemandeTarif?.ListePersonnes[0]?.Prenom &&
                    (
                        data?.DemandeTarif?.ListePersonnes[0]?.Nom === data?.DemandeTarif?.ListePersonnes[0]?.Prenom ||
                        data?.DemandeTarif?.ListePersonnes[0]?.BirthName === data?.DemandeTarif?.ListePersonnes[0]?.Prenom
                    )
                ) &&
                <div className={'row'}>
                    <div className={'col-12 col-md-6 mt-0'}>
                        <div className={'bg-warning p-4 rounded mt-3 f-14 text-center'}>
                            <p className={'mb-0'}>Votre nom et prénom sont identiques.<br/>Merci de vérifier vos informations avant de continuer.</p>
                        </div>
                    </div>
                </div>
            }

            <QuestionLabel className={'f-16 mt-4'}>Date de naissance{civilitePrincipale === '3' ? ' du conducteur' : ''}<sup
                className={'text-danger'}>*</sup></QuestionLabel>
            <div className={'row'}>
                <div className={'col-12 col-md-6 mt-3 mt-md-6'}>
                    <Field
                        component={DateField}
                        name={'DemandeTarif[ListePersonnes][0][DateNaissance]'}
                        label={'JJ/MM/AAAA'}
                        validate={[required, maxDateToday, min18Year]}
                    />
                </div>
            </div>

            <QuestionLabel className={'f-16 mt-4'}>Pays de
                naissance{civilitePrincipale === '3' ? ' du conducteur' : ''}{!isAvenant &&
                    <sup className={'text-danger'}>*</sup>}</QuestionLabel>
            <div className={'row'}>
                <div className={'col-12 col-md-6 mt-3 mt-md-6'}>
                    <Field
                        component={Autocomplete}
                        typeFormat={'postal'}
                        name={`DemandeTarif[ListePersonnes][0][BirthCountry]`}
                        placeholder={'Exemple : France'}
                        data={countries}
                        disabled={false}
                        required={!isAvenant ? required : null}
                        onChange={() => {
                            unsetValue('DemandeTarif[ListePersonnes][0][BirthCity]')
                            unsetValue('DemandeTarif[ListePersonnes][0][BirthDepartment]')
                        }}
                    />
                </div>
            </div>

            <QuestionLabel className={'f-16 mt-4'}>Ville de naissance{!isAvenant &&
                <sup className={'text-danger'}>*</sup>}</QuestionLabel>
            <div className={'row'}>
                <div className={'col-12 col-md-6 mt-3 mt-md-6'}>
                    {
                        !capbirthIsDown && birthCountry === 'FRA' ?
                            <Field
                                name={'DemandeTarif[ListePersonnes][0][BirthCity]'}
                                component={AutoCompleteBirth}
                                setErrorLoadingCountries={setErrorLoadingCountries}
                                setCapBirthIsDown={setCapBirthIsDown}
                                country={birthCountry}
                                validate={!isAvenant ? required : null}
                                disabled={false}
                                onChangeCityFunction={(value, listVilles) => {
                                    changeValue(`DemandeTarif[ListePersonnes][0][BirthDepartment]`, null)
                                    if (value) {
                                        const villeSelected = _.find(listVilles, ['value', value]);
                                        if (villeSelected?.department) {
                                            changeValue(`DemandeTarif[ListePersonnes][0][BirthDepartment]`, villeSelected.department)
                                        }
                                    }
                                }}
                            /> : <Field
                                component={Text}
                                name={'DemandeTarif[ListePersonnes][0][BirthCity]'}
                                normalize={v => v.toUpperCase()}
                                validate={!isAvenant ? required : null}
                            />
                    }
                </div>
            </div>


            <QuestionLabel className={'f-16 mt-4'}>Ville / Code postal<sup
                className={'text-danger'}>*</sup></QuestionLabel>
            <div className={'row'}>
                <div className={'col-12 col-md-6 mt-3 mt-md-6'} data-name={'DemandeTarif[ListePersonnes][0][Ville]'}>
                    <PostalCodeCity
                        icon={'pin'}
                        id={'lieuStationnement'}
                        nameVille={'DemandeTarif[ListePersonnes][0][Ville]'}
                        changeValue={changeValue}
                        nameCodePostal={'DemandeTarif[ListePersonnes][0][CP]'}
                        validate={[required, excludeCodePostal]}
                        defaultValue={postalCodeCity}
                    />
                    <p className={'text-grey m-0'}><small>Ex: 75011 PARIS</small></p>
                </div>
            </div>
            <p className={'m-0 text-grey'}><small>{getTexteBySource('assureMonaco')}</small></p>

            <CAPAdresseTrottinetteVae changeValue={changeValue} setHasSearchAddress={setHasSearchAddress} data={data}/>

            <QuestionLabel className={'f-16 mt-4'}>N° de téléphone<sup className={'text-danger'}>*</sup></QuestionLabel>
            <div className={'row'}>
                <div className={'col-12 col-md-6 mt-3 mt-md-6'}>
                    <Field
                        type={'tel'}
                        component={Text}
                        name={'DemandeTarif[ListePersonnes][0][TelPortable]'}
                        icon={'phone'}
                        iconSize={18}
                        validate={[required, validatePhone]}
                        normalize={normalizeTel}
                        onChange={e => changeValue('DemandeContrat[SignaturePhone]', normalizeTel(e.target.value))}
                    />
                </div>
            </div>
            <p className={'m-0 text-grey'}><small>{getTexteBySource('comNumero')}</small></p>

            <div className="d-none">
                <Field component={'input'} name={'emailOnError'} type={"hidden"} validate={onError}/>
            </div>

            <QuestionLabel className={'f-16 mt-4'}>{getTexteBySource('adresseEmail')}<sup
                className={'text-danger'}>*</sup></QuestionLabel>
            <div className={'row'}>
                <div className={'col-12 col-md-6 mt-3 mt-md-6'}>
                    <Field
                        component={Text}
                        name={'DemandeTarif[ListePersonnes][0][Email]'}
                        icon={'envelope'}
                        validate={[required, validateEmail]}
                        iconSize={18}
                        onChange={e => changeValue('DemandeContrat[SignatureEmail]', e.target.value)}
                    />
                </div>
            </div>

            <QuestionLabel className={'f-16 mt-4'}>{getTexteBySource('adresseEmailValidation')}<sup
                className={'text-danger'}>*</sup></QuestionLabel>
            <div className={'row'}>
                <div className={'col-12 col-md-6 mt-3 mt-md-6'}>
                    <Field
                        component={Text}
                        name={'EmailValidation'}
                        icon={'envelope'}
                        validate={[required, validateEmail]}
                        iconSize={18}
                    />
                </div>
            </div>

            {
                emailOnError && emailOnError === 'onError' && <div className="alert alert-danger mt-3" role="alert">
                    <p className={'m-0'}>Les deux adresses emails doivent être identiques.</p>
                </div>
            }

            <p className={'m-0 text-grey'}><small>{getTexteBySource('comEmail')}</small></p>

            {source === 'courtier' && <Fragment>
                <QuestionLabel className={'f-16 mt-4'}>Adresse email du courtier<sup
                    className={'text-danger'}>*</sup></QuestionLabel>
                <div className={'row'}>
                    <div className={'col-12 col-md-6 mt-3 mt-md-6'}>
                        <Field
                            component={Text}
                            name={'DemandeDevis[EmailCourtier]'}
                            icon={'envelope'}
                            validate={[required, validateEmail, validateEmailDifferente]}
                            iconSize={18}
                        />
                    </div>
                </div>
            </Fragment>}

            {isDirect && !isMB && <Fragment>

                <div className={'row mt-5'}>
                    <div className={'col'}>
                        <Field
                            name="DemandeDevis[OptinUtilisationDonnees]"
                            component={Checkbox}
                            type={"checkbox"}
                            format={v => v === '1'}
                            normalize={v => v ? '1' : '0'}
                        >
                            <div className={'mt-2 ml-2'}>Je consens à ce que les partenaires d’APRIL Moto me
                                communiquent des offres commerciales.
                            </div>
                        </Field>
                    </div>
                </div>
                <div className={'row mt-4'}>
                    <div className={'col'}>
                        <Field
                            name="opt-out-amo"
                            component={Checkbox}
                            type={"checkbox"}
                        >
                            <div className={'mt-2 ml-2'}>Je m’oppose à ce qu’APRIL Moto m’informe de ses nouvelles
                                offres similaires.
                            </div>
                        </Field>
                    </div>
                </div>
            </Fragment>}

            <ChampsObligatoires/>

            <div className={'bg-grey p-4 rounded text-left mt-5'}>
                <p>{getTexteBySource('donneePersoRenseignez')}</p>
                <p>{getTexteBySource('donneePersoTraitee')}</p>
            </div>


            <div className={'mt-5'}>

                <button
                    className={`${!valid && 'disabled'} btn btn-primary medium-rounded float-right btn-arrow`}
                    onClick={valid ? handleSubmit(form => myPostSaveDevis(form)) : null}
                    data-id="btn-next"
                >
                    {devisOrSubscribe === 'devis' ? 'OBTENIR LE DEVIS' : 'CONTINUER'}
                </button>
            </div>

            {
                devisOrSubscribe === 'devis' && saveDevisLoading === false && saveDevisError === false &&
                <PopupDevisContainer {...props} />
            }
        </div>
    );
};

export default Coordonnees;
