export const DEMANDE_RAPPEL = '[DemandeRappel]'

export const POST_DEMANDE_RAPPEL = `${DEMANDE_RAPPEL} Post`

export const postDemandeRappel = ({query, form}) => ({
    type: POST_DEMANDE_RAPPEL,
    payload: {
        data: query,
        body: {...form}
    }
})
