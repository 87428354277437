export const UI = '[Ui]'

export const SET_LOADER = `${UI} SET_LOADER`
export const SET_ERROR = `${UI} SET_ERROR`
export const SET_MAINTENANCE = `${UI} SET_MAINTENANCE`
export const SET_MAX_STEP = `${UI} SET_MAX_STEP`
export const SET_STEP = `${UI} SET_STEP`
export const SET_INDEX_QUESTION = `${UI} SET_INDEX_QUESTION`
export const SET_DEVIS_OR_SUBSCRIBE = `${UI} SET_DEVIS_OR_SUBSCRIBE`
export const SET_TARIF_ERROR = `${UI} SET_TARIF_ERROR`
export const SET_COUNTRIES = `${UI} SET_COUNTRIES`

export const setLoader = ({state, entity}) => ({
    type: `${entity} ${SET_LOADER}`,
    payload: state,
    meta: entity
})

export const setError = ({state, entity}) => ({
    type: `${entity} ${SET_ERROR}`,
    payload: state,
    meta: entity
})

export const setMaintenance = ({state, entity}) => ({
    type: `${entity} ${SET_MAINTENANCE}`,
    payload: state
})

export const setMaxStep = ({maxStep}) => ({
    type: SET_MAX_STEP,
    payload: maxStep
})

export const setStep = ({step}) => ({
    type: SET_STEP,
    payload: step
})

export const setIndexQuestion = ({index}) => ({
    type: SET_INDEX_QUESTION,
    payload: index
})

export const setDevisOrSubscribe = ({state}) => ({
    type: SET_DEVIS_OR_SUBSCRIBE,
    payload: state
})

export const setErrorTarif = ({data}) => ({
    type: SET_TARIF_ERROR,
    payload: data
})

export const setCountries = ({countries}) => ({
    type: SET_COUNTRIES,
    payload: countries
})
